.not-found {
    .not-found__container {
        display: flex;
        justify-content: space-between;
        margin-top: 45px;
        .not-found__container-left {
            position: relative;
            .fourofour {
                @include flex-center;
                margin-top: 40px;
                @media screen and (min-width: 912px) {
                    margin-top: 160px;
                }
                h2 {
                    font-size: 50px;
                    font-weight: 700;
                    line-height: 140%;
                    color: $main-dark-blue;
                }
            }
            .content {
                font-style: normal;
                font-weight: 700;
                font-size: 39px;
                line-height: 140%;
                color: $main-dark-blue;
            }
            .subtitle {
                margin-top: 12px;
                margin-bottom: 24px;
                margin-inline: unset;
                text-align: left;
                width: 100%;
            }
            .bottom-btn {
                width: auto;
            }
        }
    }
    .not-found__container-right {
        position: relative;
        @media screen and (max-width: 912px) {
            display: none;
        }
        .image-container {
            position: absolute;
        }

        .two {
            top: 50px;
            right: 53%;
        }
        .three {
            top: 35px;
            right: 5%;
        }
    }
    .image-container {
        width: 100px;
        height: 25px;
    }

    .vects-container {
        position: absolute;
        width: 306px;
        height: 426px;
        @media screen and (max-width: 912px) {
            display: none;
        }
    }
    .vector-container {
        width: 648px;
        margin-top: 134px;
    }
    .wheel-container {
        width: 54px;
        height: 54px;
    }
}
