.need-help-container {
    // background-color: #FFFFFF;

    .wrapper {
        padding-block: 56px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #f2f2f2;

        @media (max-width: 768px) {
            padding-block: 32px !important;
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            gap: 40px;
        }

        .segment-info {
            h2.title {
                font-size: 20px;
                line-height: 18px;
                font-weight: 700;
                color: $black;

                @media screen and (min-width: 640px) {
                    font-size: 22px;
                    line-height: 20px;
                }

                @media screen and (min-width: 1200px) {
                    font-size: 25px;
                    line-height: 22px;
                }
            }

            .subtitle {
                font-weight: 400;
                font-size: 14px;
                color: $grey-scale-dark-grey;

                @media screen and (min-width: 640px) {
                    font-size: 15px;
                    line-height: 20px;
                }

                @media screen and (min-width: 1200px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        .contact-info {
            display: flex;
            gap: 24px;
            flex-wrap: wrap;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                gap: 20px;
                width: 100%;
            }

            .info-container {
                min-width: 250px;
                height: 82px;
                padding: 16px;
                background-color: #fafafa;
                border: 1px solid #f2f2f2;
                border-radius: 5px;
                display: flex;
                align-items: center;
                gap: 8px;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }

                .icon {
                    height: 48px;
                    width: 48px;
                    background-color: #175adc;
                    border-radius: 50%;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .label {
                    color: $black;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 22px;

                    @media screen and (min-width: 640px) {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                    }

                    @media screen and (min-width: 1024px) {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                    }

                    @media screen and (min-width: 1400px) {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }

                .value {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22.4px;
                    color: #8b8b8b;
                }
            }
        }
    }
}
