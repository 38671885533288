@mixin regu-font {
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
@mixin regular-font {
    @include regu-font;
    font-size: 14px;
}
@mixin bold-font {
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

@mixin brand-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $main-dark-blue;
    text-align: center;
}
@mixin input-font {
    @include regular-font;
    color: $light-black;
    cursor: text;
}
@mixin input-placeholder {
    @include regular-font;
    color: $light-medium-b2;
    text-transform: capitalize;
}

@mixin flex-center {
    display: flex;
    align-items: center;
}

@mixin title-style {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

@mixin text-style-lg {
    font-size: 16px;
}

@mixin cramp-lines($lines) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
}

.text-capitalize {
    text-transform: capitalize;
}

.mb-0 {
    margin-bottom: 0px !important;
}
.mb-12 {
    margin-bottom: 12px;
}

.position-absolute {
    position: absolute;
}

.top--2px {
    top: -2px;
}

.fix-sofia-baseline {
    position: relative;
    top: 1.4px;
}

.default-bold-font {
    font-weight: 700;
    font-size: 14px;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-white {
    background-color: #ffffff;
}
.bg-medium-white {
    background-color: #f2f2f2;
}
.bg-light-white {
    background-color: #fafafa;
}

.bg-dark-blue-tint-5 {
    background-color: #d7deea;
}
