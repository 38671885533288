.navbar-white {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    background-color: $white;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
    color: $light-black;
    height: 60px;
    box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.05);
    @media screen and (min-width: 1024px) {
        height: 80px;
    }
    .logo-container {
        top: 0;
        width: 88px;
        height: 67px;
        position: absolute;
        border-radius: 50%;
        left: 15px;

        @media screen and (min-width: 768px) {
            width: 100px;
            height: 80px;
            top: 0;
            left: 30px;
        }
        @media screen and (min-width: 1024px) {
            width: 125px;
            height: 101px;
        }
        @media screen and (min-width: 1200px) {
            // left: 120px;
            height: 142px;
            margin-top: -26px;
            left: 60px;
        }
        @media screen and (min-width: 1440px) {
            left: 120px;
        }
    }
    .navbar-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        position: absolute;
        right: 15px;
        top: 10px;
        @media screen and (min-width: 768px) {
            right: 30px;
        }
        @media screen and (min-width: 1024px) {
            top: 19px;
        }
        @media screen and (min-width: 1200px) {
            right: 120px;
        }

        .navbar-right_linksMobile {
            display: flex;
            align-items: center;
            gap: 24px;
            @media screen and (min-width: 1024px) {
                display: none;
            }
            .navbar-right-links {
                list-style: none;
                display: flex;
                align-items: center;
                gap: 16px;
                margin: 0;
                padding: 0;
                height: 36px;
                padding-right: 24px;
                border-right: 1px solid $light-black;
                @media screen and (min-width: 1024px) {
                    padding-right: 40px;
                }
                li {
                    @include regular-font;
                    color: $light-black;
                    &:hover {
                        color: $main-blue;
                    }
                    a {
                        color: $light-black;
                        text-decoration: none;
                        text-transform: capitalize;
                        font-family: Sofia-Pro;
                        :hover {
                            color: $main-blue;
                        }
                    }
                    .navbar-right-link.active {
                        color: $light-black;
                    }
                    .navbar-right-link-active {
                        color: $main-blue;
                        // border-bottom: 2px solid $main-blue;
                    }
                    .navbar-right-link-active:after {
                        display: block;
                        content: '';
                        border-bottom: 2px solid $main-blue;
                    }
                }
                li:after {
                    display: block;
                    content: '';
                    border-bottom: solid 2px $main-blue;
                    transform: scaleX(0);
                    transition: transform 250ms ease-in-out;
                }
                li:hover:after {
                    transform: scaleX(1);
                    color: $main-blue;
                }
                a:hover {
                    color: $main-blue;
                }
                .navbar-right-link.active {
                    color: $light-black;
                }
            }
            .navbar-contact {
                border-right: 1px solid $light-black;
            }
        }
        .navbar-right__desktop {
            .navbar-right__links {
                border-right: 1px solid $light-black;
                .navbar-right-links {
                    li {
                        color: $light-black;
                    }
                    li:after {
                        display: block;
                        content: '';
                        border-bottom: solid 2px $main-blue;
                        transform: scaleX(0);
                        transition: transform 250ms ease-in-out;
                        margin-top: -2px;
                    }
                    li:hover:after {
                        transform: scaleX(1);
                        color: $main-blue;
                    }
                    a:hover {
                        color: $main-blue;
                    }
                }
            }
            .navbar-contact {
                border-right: 1px solid $light-black;
                .contact-number {
                    .contact-number-call {
                        color: $light-black;
                    }
                    .contact-number-number {
                        color: $main-dark-blue;
                    }
                }
            }
        }
        .menu-btn {
            width: 76px;
            height: 44px;
            background-color: $white;
            display: flex;
            align-items: center;
            padding: 12px;
            gap: 8px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            cursor: pointer;
            .seperator {
                border: 1px solid #595959;
                width: 0;
            }
        }
    }
    .navbar-right__desktop {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex;
            align-items: center;
            gap: 40px;
        }
        .navbar-right__links {
            border-right: 1px solid #ffffff;
            padding-right: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            .navbar-right-links {
                list-style: none;
                display: flex;
                align-items: center;
                gap: 24px;
                margin: 0;
                li {
                    @include regular-font;

                    color: #ffffff;
                    a {
                        text-decoration: none;
                    }
                    .navbar-right-link {
                        color: #595959;
                        transition: all 0;
                    }
                    .navbar-right-link:after {
                        display: block;
                        content: '';
                        border-bottom: solid 2px $main-blue;
                        transform: scaleX(0);
                        transition: transform 250ms ease-in-out;
                    }
                    .navbar-right-link :hover:after {
                        transform: scaleX(1);
                        color: $main-blue;
                    }
                    .navbar-right-link-active {
                        color: $main-blue;
                    }
                    .navbar-right-link-active:after {
                        display: block;
                        content: '';
                        border-bottom: 2px solid $main-blue;
                    }
                }
                a:hover {
                    color: $main-blue;
                }
            }
        }
        .navbar-contact {
            display: flex;
            align-items: center;
            gap: 8px;
            border-right: 1px solid #ffffff;
            padding-right: 40px;
            .whatsapp-logo-container {
                width: 40px;
                height: 40px;
            }
            .contact-number {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .contact-number-call {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 140%;
                    color: $light-white;
                }
                .contact-number-number {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;
                    color: $white;
                }
            }
        }
        .menu-btn {
            width: 88px;
            height: 44px;
            background-color: $white;
            display: flex;
            align-items: center;
            padding: 12px;
            gap: 12px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            cursor: pointer;
            .seperator {
                border: 1px solid #595959;
                width: 0;
                height: 20px;
            }
        }
    }
}
