.press-container {
    .press-banner {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 32px;
        padding-bottom: 80px;
        @media screen and (max-width: 767px) {
            padding-bottom: 40px;
        }
        &-main {
            width: 85%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 140px;
            gap: 68px;
            // @media screen and (max-width: 1023px) {
            //     width: 80%;
            // }
        }
        &-image {
            min-width: 446px;
            max-width: 446px;
            height: 351px;
            & > img {
                object-fit: cover;
            }
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
        &-info {
            text-align: left;
            & > h2 {
                font-weight: 700;
                font-size: clamp(20px, 4vw, 31px);
                line-height: 140%;
                color: $main-dark-blue;
            }
            & > p {
                font-size: clamp(11px, 4vw, 16px);
                line-height: 140%;
                color: $medium-black;
                padding-top: 16px;
            }
            &-sectionSeperator {
                margin: 0;
                margin-bottom: 12px;
            }
        }
    }

    .press-media {
        background-color: $light-white;
        &-main {
            padding-top: 60px;
            padding-bottom: 60px;
            width: 85%;
            margin: auto;
            @media screen and (max-width: 767px) {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }
        &-info {
            text-align: center;
            margin-bottom: 48px;
            & > h2 {
                font-weight: 700;
                font-size: clamp(20px, 4vw, 31px);
                line-height: 140%;
                color: $main-dark-blue;
            }
            & > p {
                font-size: clamp(11px, 4vw, 16px);
                line-height: 140%;
                color: $medium-black;
                padding-top: 16px;
            }
            &-sectionSeperator {
                margin: auto;
                margin-bottom: 12px;
            }
        }
        &-contents {
            & > :not(:last-child) {
                margin-bottom: 112px;
            }
        }
        &-contentrow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 120px;
            @media screen and (max-width: 1023px) {
                flex-direction: column;
                gap: 32px;
                align-items: flex-start;
            }
            &-desc {
                font-size: clamp(11px, 4vw, 16px);
                line-height: 140%;
                &-text {
                    color: $medium-black;
                }
                &-date {
                    font-family: Sofia-Pro !important;
                    font-weight: 700 !important;
                    color: $black !important;
                    padding-top: 12px !important;
                }
                & > a {
                    display: inline-block;
                    padding-top: 24px;
                }
            }
            &-image {
                min-width: 450px;
                max-width: 450px;
                height: 200px;
                & > img {
                    // object-fit: cover;
                    object-fit: contain;
                }
                @media screen and (max-width: 535px) {
                    min-width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
    .contentRowReverse {
        flex-direction: row-reverse;
        @media screen and (max-width: 1023px) {
            flex-direction: column;
            gap: 32px;
            align-items: flex-start;
        }
    }
}
