.account-page {
    .navbar.active {
        @media screen and (max-width: 767px) {
            .navbar-right {
                width: 100%;
                right: 0;
                .navbar-right_linksMobile {
                    margin-inline: auto;
                    .account-nav {
                        width: 100%;
                    }
                }
            }
        }
    }
    .account {
        margin-top: 24px;
        @media screen and (min-width: 768px) {
            margin-top: 60px;
        }
        .title-seperator {
            display: none;
            @media screen and (min-width: 1024px) {
                display: inline-block;
            }
            .title {
                font-size: 39px;
                margin-bottom: 24px;
            }
        }
        .user-info {
            display: none;

            .profile-image {
                width: 60px;
                height: 60px;
            }
            .user-name-number {
                .user-name {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;
                    color: $medium-black;
                    margin-bottom: 7px;
                    text-transform: capitalize;
                }
                .user-number {
                    @include regular-font;
                    color: $medium-black;
                }
            }
        }
        .account-content {
            border-top: none;
            position: relative;
            @media screen and (min-width: 1024px) {
                border-top: 1px solid #f2f2f2;
                padding-top: 36px;
                margin-bottom: 0;
            }
            .sec-seperator {
                width: 100px;
            }
            .title {
                font-size: 20px;
                @media screen and (min-width: 1024px) {
                    font-size: 25px;
                    margin-bottom: 32px;
                }
            }

            .account-tab {
                flex-direction: column;
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                    gap: 48px;
                }
                .ant-tabs-nav {
                    @media screen and (min-width: 1024px) {
                        margin-bottom: 60px;
                    }
                    .ant-tabs-nav-wrap {
                        .ant-tabs-nav-list {
                            width: auto !important;
                            display: none;
                            @media screen and (min-width: 768px) {
                                display: inline-flex;
                            }
                            .ant-tabs-tab {
                                position: relative;
                                margin: 0;
                                padding-top: 16px;
                                padding-bottom: 18px;
                                width: 256px;
                                padding-left: 18px;
                                &::after {
                                    content: '';
                                    width: 24px;
                                    border-right: 1px solid #f2f2f2 !important;
                                    height: 100%;
                                    position: absolute;
                                    right: 0;
                                    background-color: #fff;
                                }
                                .ant-tabs-tab-btn {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: $medium-black;
                                    width: 100%;

                                    .tab-header {
                                        @include flex-center;
                                        gap: 11px;
                                        justify-content: space-between;
                                        width: 100%;
                                        .image-title {
                                            @include flex-center;
                                            gap: 10px;
                                        }
                                        .icon {
                                            width: 16px;
                                            height: 17px;
                                            margin-top: -7px;
                                        }
                                        .tab-right-arrow {
                                            @media screen and (min-width: 768px) {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                            .ant-tabs-tab-active {
                                background-color: #fafafa;
                                .ant-tabs-tab-btn {
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: $main-dark-blue;
                                }
                            }
                            .ant-tabs-ink-bar {
                                left: 0 !important;
                                width: 5px;
                                background: $main-blue;
                            }
                        }
                    }
                }
                .ant-tabs-content-holder {
                    border: 0 !important;
                    .ant-tabs-content {
                        .ant-tabs-tabpane {
                            padding-left: 0 !important;
                        }
                    }
                }
            }
            .logout-btn {
                cursor: pointer;
                margin: 0;
                padding-top: 16px;
                padding-bottom: 18px;
                padding-left: 18px;
                width: 100%;
                @include flex-center;
                justify-content: center;
                gap: 11px;
                @include regular-font;
                color: $white;
                text-align: center;
                background-color: $main-blue;
                border-radius: 5px;
                margin-block: 40px;
                @media screen and (min-width: 768px) {
                    position: absolute;
                    top: 275px;
                    color: $medium-black;
                    width: 256px;
                    text-align: start;
                    display: flex;
                    justify-content: flex-start;
                    background-color: transparent;
                    margin-block: 0;
                }
                @media screen and (min-width: 1024px) {
                    top: 310px;
                }
                @media screen and (min-width: 1280px) {
                    top: 310px;
                }
                &::after {
                    content: '';
                    width: 0;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    background-color: #fff;
                    @media screen and (min-width: 768px) {
                        border-right: 1px solid #f2f2f2 !important;
                        width: 24px;
                    }
                }
                .logout {
                    width: 17px;
                    height: 17px;
                    margin-top: -7px;
                    display: none;
                    @media screen and (min-width: 768px) {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
