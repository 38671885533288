.request-car-form {
    width: 90%;
    margin: 0 auto;
    background-color: $white;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    z-index: 20;
    padding: 20px;

    .display-contents {
        >div{
            flex: 1;
            min-width: 0;
        }
    }
    @media screen and (min-width: 767px) {
        width: 80%;
        margin-inline: auto;
        transform: translate(0, -120px);
        padding: 24px;
    }
    @media screen and (min-width: 1024px) {
        width: 48.62%;
        transform: translate(0, -120px);
        padding: 28px;
    }
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
        padding: 32px;
    }
    .ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search{
        overflow: hidden;
    }
}
