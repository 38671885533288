@import '../components/sectionutils';

.car-detail-page {
    .car-slider-container {
        @media screen and (min-width: 768px) {
            display: none;
        }

        .car-title {
            margin-bottom: 12px;
        }

        .car-highlights {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        .car-header-section {
            margin-bottom: 16px;
            margin-top: 16px;
            .actions {
                display: none;
            }
        }
        .car-detail-slider {
            position: relative;
            height: 240px;
            .actions {
                display: flex;
                flex-direction: column;
                gap: 16px;
                align-items: center;
                position: absolute;
                right: 12px;
                bottom: 12px;

                .action-item {
                    display: flex;
                    align-items: center;

                    .action-icon {
                        background-color: $light-white;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 32px;
                        width: 32px;
                        svg {
                            height: 16px;
                            width: 16px;
                            align-items: center;
                            display: flex;
                        }

                        .pointer {
                            height: 16px;
                            width: 16px;
                            align-items: center;
                            display: flex; // to remove extra padding
                        }
                    }
                }
            }

            @media screen and (min-width: 500px) {
                height: 440px;
            }

            .ant-carousel {
                height: 100%;
                .slick-slider {
                    height: 100%;
                }
            }
            .hero__carousel,
            .slick-list,
            .slick-track,
            .slick-track div {
                height: 100%;
            }

            .slick-dots {
                background: transparent;
                display: inline-flex !important;
                justify-content: start !important;
                align-items: center;
                left: 10px;
                margin-left: 0;
                margin-right: 0;
                width: 82%;
                overflow: scroll;
                // padding: 6px;
                height: 24px;

                li {
                    width: 8px;
                    button {
                        opacity: 1;
                        background-color: white;
                    }
                }

                .slick-active {
                    margin-right: 20px !important;
                    button {
                        width: 25px;
                        height: 8px;
                        background-color: $main-blue;
                    }
                }

                li button {
                    width: 8px;
                    height: 8px;
                    border-radius: 5px;
                }
            }
        }
        .view-count {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 0;
            font-size: 14px;
            line-height: 19px;
            color: $light-black;
            margin-top: 8px;
        }
    }

    .car-detail-specification-section {
        position: relative;
    }

    .car-detail-cover {
        padding-top: 16px;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .car-header-section {
        margin: 8px 0;
    }

    .car-title {
        @include section-container-title();
        margin-bottom: 8px;
    }

    .car-attributes-section {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 8px;

        @media screen and (min-width: 640px) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media screen and (min-width: 640px) {
            flex-direction: row;
            justify-content: space-between;
        }

        .car-attributes {
            display: flex;
            gap: 8px;
            align-items: center;

            .attribute {
                background-color: $extra-white;
                border-radius: 5px;
                padding: 8px;
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $black;
            }
        }

        .actions {
            display: flex;
            gap: 24px;
            align-items: center;

            .action-item {
                display: flex;
                gap: 8px;
                align-items: center;

                .action-icon {
                    background-color: $light-white;
                    border-radius: 5px;
                    padding: 8px;
                    display: flex;

                    .pointer {
                        display: flex; // to remove extra padding
                    }
                }

                span {
                    color: #111111;
                    font-size: 14px;
                }
            }
        }
    }
}

.sticky-car-detail-section-list {
    position: sticky;
    top: 60px;
    background-color: #ffffff;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-white;
    z-index: 10;

    @media screen and (min-width: 1024px) {
        top: 80px;
    }
}
