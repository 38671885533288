.brand-widget {
    margin-top: 48px;
    display: flex;
    gap: 24px;
    align-items: center;
    font-family: Sofia-Pro;

    @media screen and (max-width: 525px) {
        flex-direction: column;
    }

    .brand-logo {
        img {
            height: 96px;
            width: 96px;
            object-fit: contain;
            mix-blend-mode: multiply;
        }
    }
    .brand-desc {
        h4 {
            font-size: 24px;
            font-weight: 700;
            text-transform: capitalize;
        }

        p {
            font-size: 14px;
            color: #595959;
        }
    }
}
