.primary-btn {
    padding: 8px 16px;
    gap: 10px;
    height: 50px;
    background-color: $main-blue;
    border-radius: 5px;
    color: $white;
    z-index: 1;
    text-transform: capitalize;
    margin-top: 24px;
    margin-inline: auto;
    @include flex-center;
    justify-content: center;
    &:hover {
        background: #07348d;
        box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
    }
    @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 35px;
        right: calc(50% - (160.7px / 2));
        top: 60px;
        right: 30px;
        bottom: -77px;
        width: auto;
    }
    @media screen and (min-width: 1200px) {
        right: 60px;
        bottom: 0;
    }
    @media screen and (min-width: 1440px) {
        right: 0;
    }
}
.eng {
    @media screen and (max-width: 767px) {
        right: calc(50% - (288px / 2));
    }
}
