.media-recognition-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 20px;
    padding-bottom: 20px;

    @media screen and (min-width: 640px) {
        padding-top: 30px;
        padding-bottom: 30px;
        grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (min-width: 1024px) {
        padding-top: 40px;
        padding-bottom: 40px;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
    }

    @media screen and (min-width: 1400px) {
        padding-top: 56px;
        padding-bottom: 56px;
        grid-template-columns: repeat(2, 1fr);
        gap: 86px;
    }
}

.media-recognition-block {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);

    &.bottom {
        margin-top: 24px;
        margin-left: 27px;
        margin-right: 27px;
    }

    @media screen and (min-width: 640px) {
        gap: 45px;
        &.bottom {
            margin-top: 24px;
            margin-left: 27px;
            margin-right: 27px;
        }
    }

    @media screen and (min-width: 1024px) {
        gap: 60px;
        &.bottom {
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    @media screen and (min-width: 1400px) {
        gap: 86px;
        &.bottom {
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}

.media-recognition-item {
    img {
        object-fit: contain;
    }
}
