.blog-section-card {
    background-color: transparent;
    min-width: 300px;
    cursor: pointer;
    img {
        aspect-ratio: 3/2;
        object-fit: cover;
        border-radius: 5px;
    }
}

.blog-content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.blog-type {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: $main-blue;
    text-transform: uppercase;
}

.blog-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $medium-black;
}

.blog-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $grey-scale-dark-grey;
}
