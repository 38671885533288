@import './sectionutils';

.review-section {
    background-color: white;
    padding-top: 56px;
    padding-bottom: 56px;

    @media (max-width: 768px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

.review-grid {
    overflow: scroll;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    padding: 0 16px;

    @media screen and (min-width: 768px) {
        padding: 0 40px;
    }

    @media screen and (min-width: 1200px) {
        padding: 0 40px;
    }

    @media screen and (min-width: 1440px) {
        padding: 0;
        max-width: 1280px;
        margin-inline: auto;
        width: 100%;
    }

    @media screen and (min-width: 1500px) {
        max-width: 1440px;
    }
}

.review-title-container {
    display: inline;
}

.review-section-title {
    @include section-container-title();
    margin-bottom: 16px;

    @media screen and (min-width: 640px) {
        margin-bottom: 20px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 24px;
    }
}

.review-company-ratings {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 12px;

    @media screen and (min-width: 640px) {
        margin-top: 20px;
        gap: 16px;
        flex-direction: row;
    }

    @media screen and (min-width: 1024px) {
        margin-top: 20px;
        gap: 20px;
        flex-direction: row;
    }

    @media screen and (min-width: 1400px) {
        margin-top: 24px;
        gap: 24px;
        flex-direction: row;
    }
}

.review-company-review {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 640px) {
        gap: 16px;
    }

    @media screen and (min-width: 1024px) {
        gap: 20px;
    }

    @media screen and (min-width: 1400px) {
        gap: 24px;
    }

    img {
        height: 32px;
        width: min-content;
        object-fit: contain;

        @media screen and (min-width: 640px) {
            height: 48px;
        }
    }

    .review-company-name {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: $main-dark-blue;
        text-wrap: nowrap;
    }

    .review-company-rating {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: $medium-black;
    }
}
