.selects-container {
    .error-label {
        position: absolute;
        &.in-animation {
            animation: 'inAnimation 250ms ease-in';
        }
    }
}

.selects {
    @include flex-center;
    width: 100%;
    border: 1px solid $medium-grey;
    border-radius: 5px !important;
    padding: 0 10px;
    height: 50px;
    gap: 5px;
    background-color: $extra-white;
    .ant-select-item-option-content {
        display: flex !important;
    }
    .ant-select {
        width: 100%;
        overflow: hidden;
        height: 32px;
        .ant-select-selection-item {
            @include input-font;
            .ant-select-selection-item-content {
                @include flex-center;
                gap: 10px;
            }
        }
        .ant-select-show-arrow {
            overflow: hidden;
        }
        .ant-select-selector {
            padding: 0 !important;
            @include flex-center;
            input {
                height: 100%;
                @include input-font;
            }
            .ant-select-selection-search {
                left: 0 !important;
            }
            .ant-select-selection-placeholder {
                @include input-placeholder;
            }
        }
        .ant-select-item-option-content {
            display: flex;
        }
    }

    .ant-single-select {
        .ant-select-selector {
            height: 40px;
        }
    }
    .selects-prefixicon {
        width: 24px;
        height: 24px;
        min-width: 24px;
    }
}
.select-cursor {
    cursor: pointer !important;
    .ant-select {
        .ant-select-selector {
            input {
                cursor: pointer !important;
                height: 100% !important;
            }
            height: 100% !important;
        }
    }
}
.selects-dropdown {
    @include regular-font;
    .ant-select-item-option-selected {
        color: $main-blue;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: $main-blue;
        background-color: transparent;
    }
    .ant-select-item-option {
        .ant-select-item-option-content {
            @include flex-center;
            gap: 10px;
        }
    }
}
.option-color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    min-width: 10px;
    border: 1px $main-blue solid;
}

.ant-select,
.ant-select-selector {
    height: 100%;
}
.ant-select-selector {
    height: 100% !important;
}
