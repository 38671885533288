.reset-password {
    margin-bottom: 120px;
    .reset-sep {
        margin-inline: 0;
    }
    .reset-title {
        text-align: start;
        margin-bottom: 24px;
        @media screen and (min-width: 1024px) {
            margin-bottom: 32px;
        }
    }
    .reset-input-container {
        display: flex;
        gap: 24px;
        flex-direction: column;
        .reset-input {
            width: 100%;
            border: 1px solid $medium-grey;
            border-radius: 5px !important;
            height: 50px !important;
            @include flex-center;

            padding: 0 11px;
            background-color: $extra-white;
            .error-label {
                bottom: -27px;
            }
            @media screen and (min-width: 1024px) {
                width: 42.2%;
            }
            .icon {
                width: 22px;
                height: 20px;
            }
            input {
                background-color: $extra-white;

                border-radius: 5px !important;
                width: 100%;
                height: 100%;
                padding-left: 12px !important;
                @include input-font;
                font-size: 16px;
            }
            input::placeholder {
                padding-top: 12px;
                @include input-placeholder;
                text-align: left;
                background-color: $extra-white;
            }
            .image {
                width: 26px;
                height: 24px;
            }
        }
        .note {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #878b87;
        }
        .form-btn-container {
            margin-top: 8px;
            .error-label {
                top: -30px;
            }
            @include flex-center;

            gap: 15px;
            .form-btn {
                @include flex-center;
                justify-content: center;
                padding: 8px 16px;
                gap: 10px;
                width: 120px;
                height: 50px;
                border-radius: 5px;
                @include regular-font;
                @media screen and (max-width: 767px) {
                    width: 50%;
                }
                &.send {
                    background-color: $main-blue;
                    color: $white;
                }
                &.disable {
                    background-color: $dark-gray;
                    border-radius: 5px;
                    color: $white;
                }
                &.cancel {
                    border: 1px solid $main-blue;
                    color: $main-blue;
                    background-color: $white;
                    &:hover {
                        background-color: #dee6f7;
                        border: 1px solid #175adc;
                        border-radius: 5px;
                        color: $main-blue;
                    }
                }
            }
        }
    }
}
.reset-modal {
    .title {
        margin-bottom: 24px;
    }
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
