.car-detail-gallery {
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (min-width: 768px) {
        gap: 12px;
        grid-template-columns: repeat(2, 1fr);
    }


    // image class
    .gallery-image {
        cursor: pointer;
        border-radius: 5px;
    }

    .main-image {
        width: 100%;
        border-radius: 5px;
        aspect-ratio: 127/90;
    }

    .sub-images {
        width: 100%;
        display: grid;
        gap: 6px;
        grid-template-columns: repeat(4, 1fr);

        @media screen and (min-width: 768px) {
            gap: 12px;
            grid-template-columns: repeat(2, 1fr);
        }

        .sub-image {
            width: 100%;
            border-radius: 5px;
            aspect-ratio: 31/22;

            &.final {
                position: relative;

                .view-all-image-btn {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: rgba(14, 14, 14, 0.5);
                    color: white;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    pointer-events: none; // this will ignore the button click and pass the click event to the element underneath
                }
            }

            &.hide {
                display: none;
            }
        }


    }

    .react_lightgallery_item {
        height: 100%;
    }


}