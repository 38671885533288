.history {
    margin-bottom: 140px;
    .history-sep {
        margin-inline: 0;
    }
    .history-sep {
        text-align: start;
    }
    .history-container {
        .history-holder {
            width: 100%;
            @media screen and (min-width: 1024px) {
                width: 80%;
            }
        }
        .history-holder:not(:last-child) {
            border-bottom: 1px solid #f2f2f2;
        }
    }
}
.history-comp {
    @include flex-center;
    gap: 20px;
    padding: 24px 0;
    .history-icon {
        width: 24px;
        height: 24px;
        img {
            width: auto;
            height: auto;
        }
    }
    .history-content {
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        .content {
            font-size: 16px;
            color: $medium-black;
            a {
                color: $main-blue;
                &:hover {
                    color: $main-dark-blue;
                }
            }
        }
        .time {
            font-size: 14px;
            color: $dark-gray;
        }
    }
}
