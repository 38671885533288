.recently-added-container {
    background-color: $light-white;

    .recently-added {
        margin: auto;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        position: relative;

        .recently-header {
            margin-bottom: 32px;
        }
        .recent-card__holder {
            @include flex-center;
            flex-wrap: wrap;
            gap: 20px;
            .card-holder {
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .recently-added-container {
        .recently-added {
            padding-top: 60px !important;
            padding-bottom: 60px !important;
            .recent-card__holder {
                .card-holder {
                    width: calc((100% - 20px) / 2);
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .recently-added-container {
        .recently-added {
            .recent-card__holder {
                .card-holder {
                    width: calc((100% - 40px) / 3);
                }
            }
        }
    }
}

.cheapestCar-carousel {
    .cheapestCard-holder {
        width: 467px;
        height: 376px;
        // width: 100%;
        // height: 100%;
        // @media screen and (min-width: 768px) {
        //     width: calc((100% - 20px) / 2);
        // }
        // @media screen and (min-width: 1024px) {
        //     width: calc((100% - 40px) / 3);
        // }
        .card {
            border-radius: 5px;
        }
    }
    .car-images-slider-thumbs {
        background-color: red;
        .swiper-slide {
            border: 2px solid transparent;
            width: 100%;
            height: 100px;
            border-radius: 5px;

            img {
                border-radius: 5px;
            }
            .car-images-slider-thumbs-wrapper {
                height: 96px;
            }
        }
        .swiper-slide-thumb-active {
            border: 2px solid $main-dark-blue;
        }

        .swiper-button-next {
            top: 50%;
        }
    }
}

.recently-added-link {
    color: $main-blue;
    position: absolute;
    font-weight: 700;
    &:hover {
        color: $main-blue;
    }

    @media screen and (min-width: 768px) {
        bottom: 35px;
        top: 125px;
        right: 50px;
        width: auto;
    }

    @media screen and (min-width: 1200px) {
        right: 100px;
    }

    @media screen and (min-width: 1440px) {
        right: 20px;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}

// .carousel {
//     width: 100%;
//     height: 376px;
//     .car-images-slider-thumbs {
//         .swiper-slide {
//             border: 2px solid transparent;
//             // width: 100%;
//             // height: 100px;
//             width: 100% !important;
//             height: 376px !important;
//             border-radius: 5px;

//             img {
//                 border-radius: 5px;
//             }
//             .car-images-slider-thumbs-wrapper {
//                 width: 100%;
//                 // height: 96px;
//                 // height: 376px;
//             }
//         }
//         .swiper-slide-thumb-active {
//             border: 2px solid $main-dark-blue;
//         }

//         .swiper-button-next {
//             top: 50%;
//         }
//     }
// }
