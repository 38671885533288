@mixin input-font {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: $light-black;
}

@mixin after-element($bg-color) {
    content: '';
    position: absolute;
    width: 100%;
    height: 90%;
    top: 21px;
    left: 0;
    background-color: $bg-color;
    z-index: -1;

    @media screen and (max-width: 1200px) {
        top: 17px;
    }

    @media screen and (max-width: 992px) {
        top: 13px;
    }
}

.section-hero {
    background: $background-primary;
}

.hero-container {
    padding-top: 32px;
    padding-bottom: 32px;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
        padding-top: 15px;
        padding-bottom: 32px;
    }
}

.hero {
    &__row {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 32px;

        @media (max-width: 1023.98px) {
            grid-gap: 20px;
        }
    }

    &__col {
        &--left {
            grid-column: span 5;
            display: flex;
            align-items: center;

            @media (max-width: 1024px) {
                grid-column: span 6;
            }

            @media (max-width: 768px) {
                grid-column: span 12;
            }
        }

        &--right {
            grid-column: span 7;
            display: flex;
            flex-direction: column;

            .ant-carousel,
            .hero__carousel,
            .slick-list,
            .slick-track,
            .slick-track div {
                height: 100%;
            }

            .hero__carousel {
                .slick-dots {
                    background: rgba(17, 17, 17, 0.6);
                    display: inline-flex !important;
                    align-items: center;
                    left: 50%;
                    transform: translateY(-50%);
                    margin-left: 0;
                    margin-right: 0;
                    border-radius: 4px;
                    padding: 6px;
                    width: 48px;
                    height: 24px;

                    .slick-active {
                        width: 12px;
                        height: 12px;

                        button {
                            width: 12px;
                            height: 12px;
                        }
                    }

                    li {
                        width: 6px;
                        height: 6px;
                    }

                    li button {
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                    }
                }
            }

            @media (max-width: 1024px) {
                grid-column: span 6;
                display: flex;
            }

            @media (max-width: 769px) {
                grid-column: span 12;
                display: none;
            }
        }
    }

    &__content {
        padding-left: 80px;
        padding-right: 0px;
        margin: 0 auto;

        @media (min-width: 1500px) {
            padding-left: 0px;
        }

        @media (max-width: 1440px) {
            padding-left: 80px;
        }

        @media (max-width: 1200px) {
            padding-left: 40px;
        }

        @media (max-width: 768px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    &__title {
        font-size: 76px;
        font-weight: 700;
        line-height: 93.6px;
        text-transform: capitalize;
        margin-bottom: 32px;

        @media screen and (max-width: 1200px) {
            font-size: 60px;
            line-height: 78px;
        }
        @media screen and (max-width: 992px) {
            font-size: 48px;
            line-height: 64px;
        }

        @media (max-width: 768px) {
            text-align: center;
        }

        @media screen and (min-width: 640px) and (max-width: 768px) {
            max-width: 420px;
            margin: 0 auto 32px;
        }

        &--bg-yellow {
            position: relative;
            z-index: 10;

            &::after {
                @include after-element($bg-yellow);
            }
        }

        &--bg-green {
            position: relative;
            z-index: 10;

            &::after {
                @include after-element($bg-green);
            }
        }
    }

    &__sub-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
    }

    &__sub-text-link {
        font-weight: 700;
        text-decoration: underline;
        text-transform: capitalize;
        color: $black;
        &:hover {
            color: $main-blue;
            text-decoration: underline;
        }
    }

    &__photo {
        max-width: 100%;
        height: 100%;
        object-fit: cover;

        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        @media (min-width: 1450px) {
            border-radius: 5px;
        }
        @media (max-width: 768px) {
            border-radius: 0px;
            display: none;
        }
    }

    &__input-container {
        position: relative;
    }

    &__input-icon {
        position: absolute;
        top: 14px;
        right: 16px;
    }

    &__input {
        @include input-font;
        padding: 14px 40px 14px 16px;
        width: 100%;
        border: 1px solid $dark-blue-tint5;
        border-radius: 5px;
        transition: border-color 0.3s;

        &:focus {
            border-color: $dark-blue-tint4;
            outline: none;
            box-shadow: 0 0 0 2px $dark-blue-tint4;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__button {
        font-size: 14px;
        line-height: 19.6px;
        font-weight: 700;
        color: $extra-white;
        background-color: $main-blue;
        padding: 15px;
        border-radius: 5px;
        text-transform: uppercase;
        transition: background-color 0.3s;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            background-color: darken($color: $main-blue, $amount: 10%);
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px $dark-blue;
        }

        &:active {
            background-color: lighten($color: $main-blue, $amount: 5%);
        }

        &__search-text {
            text-transform: uppercase;
        }
    }

    &__dropdown-content {
        background-color: $extra-white;
        border: 1px solid $dark-blue-tint5;
        border-radius: 5px;
        padding: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__dropdown-title {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16.8px;
        color: $light-black;
        margin-bottom: 8px;
    }

    &__dropdown-cards {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 12px;
    }

    &__dropdown-card {
        grid-column: span 6;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        &:last-child {
            display: none;
        }

        @media (min-width: 426px) {
            grid-column: span 4;

            &:last-child {
                display: flex;
            }
        }

        &__image-wrapper {
            width: 100%;
            height: 130px;
            flex: 0 0 auto;
        }

        &__image {
            border-radius: 5px;
        }

        &__content {
            position: relative;
            padding: 12px;
            border-radius: 5px;
            background-color: $white;
            margin-top: -10px;
            z-index: 10;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__title {
            font-size: 12px;
            line-height: 16.8px;
            color: $light-black;
            margin-bottom: 2px;
            text-transform: capitalize;
            word-wrap: break-word;
        }

        &__price {
            font-size: 14px;
            line-height: 19.6px;
            font-weight: 700;
            color: $black;
            word-wrap: break-word;
        }

        &__travelled {
            display: flex;
            gap: 4px;
            align-items: center;
            margin-top: 8px;
            word-wrap: break-word;

            & img {
                width: 16px;
                height: 16px;
            }
        }
    }
}
