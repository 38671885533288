.banner-info-section {
    position: relative;
    overflow: hidden;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1441px) {
        border-radius: 5px;
    }

    .banner-info-circle {
        position: absolute;
        top: -55px;
        right: -126px;
        background-color: $bright-yellow;
        height: 442px;
        width: 442px;
        border-radius: 50%;
        display: none;
        @media screen and (min-width: 1024px) {
            display: block;
        }
    }

    .banner-info-container {
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        justify-content: space-around;
        padding-top: 64px;
        padding-bottom: 64px;
        padding-left: 20px;
        padding-right: 20px;
        z-index: 10;
        position: relative;

        @media (max-width: 768px) {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        .banner-info-image {
            width: 454px;
            height: 202px;
            border-radius: 5px;
            display: none;
            @media screen and (min-width: 1024px) {
                display: block;
            }
        }
    }

    .banner-info-section-content {
        max-width: 623px;
    }

    .banner-info-section-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        margin-bottom: 8px;

        @media screen and (min-width: 640px) {
            font-size: 22px;
            line-height: 30px;
        }

        @media screen and (min-width: 1200px) {
            font-size: 25px;
            line-height: 35px;
        }
    }

    .banner-info-section-subtitle {
        font-size: 400;
        font-size: 14px;
        line-height: 19px;
        color: $light-white;
        margin-bottom: 24px;
    }

    .banner-info-link {
        border-radius: 5px;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        background-color: white;
        color: $main-blue;
        padding: 15px 25px;
    }
}
