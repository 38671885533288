.download-section {
    background-color: $white;
    padding-top: 56px;
    padding-bottom: 56px;

    @media (max-width: 768px) {
        padding-top: 0;
        padding-bottom: 0;
    }
    @media screen and (min-width: 768px) {
        .center {
            margin-inline: auto;
        }
        .title {
            text-align: center;
            margin-bottom: 8px;
        }
        .subtitle {
            text-align: center;
        }
    }
}
.download-app-container {
    // padding: 0 100px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 48px;

    @media screen and (max-width: 768px) {
        padding: 40px 40px !important;
        align-items: center;
        justify-content: start;
    }

    @media screen and (max-width: 640px) {
        padding: 34px 16px !important;
    }

    .content-div {
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            // align-items: center;
        }

        h2.download-app-title {
            font-size: 20px;
            line-height: 28px;
            font-weight: 700;
            color: $black;

            @media screen and (min-width: 640px) {
                font-size: 22px;
                line-height: 20px;
            }

            @media screen and (min-width: 1200px) {
                font-size: 25px;
                line-height: 22px;
            }
        }

        p {
            font-size: 14px;
            color: $light-black;
            line-height: 18px;
            margin: 8px 0 24px 0;

            @media screen and (min-width: 640px) {
                font-size: 14px;
                line-height: 26px;
            }

            @media screen and (min-width: 1200px) {
                font-size: 14px;
                line-height: 28px;
            }
        }

        .playstore-img {
            img {
                width: 180px;
                height: auto;
            }
        }
    }

    .image-div {
        img {
            height: auto;
            width: auto;

            @media screen and (max-width: 1200px) {
                width: 300px;
            }
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}

.filterCarsFooter {
    padding-top: 40px !important;
    padding-bottom: 40px !important;

    @media screen and (min-width: 768px) {
        padding: 40px 30px !important;
    }

    p {
        display: flex;
        align-items: center;
        gap: 6px;
        color: $light-black;
        cursor: pointer;

        &:hover {
            color: $dark-blue;

            svg {
                color: $dark-blue;
            }
        }

        svg {
            color: $main-blue;
        }
    }
}
