.section-customer-assurance {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}

.customer-assurance-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 56px 0px;
    gap: 16px;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 1440px) {
        padding-left: 80px;
        padding-right: 80px;
    }

    @media (max-width: 1200px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (max-width: 992px) {
        padding: 24px 30px 24px;
        justify-content: flex-start;
    }

    @media (max-width: 768px) {
        padding: 24px 15px 24px;
    }
}

.customer-assurance-box {
    display: flex;
    gap: 12px;
    flex: 0 0 300px;

    &__title {
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 700;
        color: $black;
    }

    &__text {
        font-size: 14px;
        line-height: 18.2px;
        font-weight: 400;
        color: $light-black;
    }

    &__photo {
        width: 56px;
        height: 56px;
    }
}
