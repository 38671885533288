@import './sectionutils';

.match-budget-title {
    width: 100%;
}
.match-budget-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    overflow: auto;
    max-width: 100%;
    margin-top: 8px;

    button {
        cursor: pointer;
        background-color: transparent;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        border-bottom: transparent solid 2px;
        color: $light-black;
        text-wrap: nowrap;

        &.active {
            color: $black;
            border-color: $main-dark-blue;
        }
        &:hover:not(.active) {
            color: $black;
        }
    }
}

.match-budget-header {
    @include section-container-heading-row(end);
    border-bottom: $medium-white solid 1px;
}

.match-budget-heading {
    @include section-container-title();
    margin-bottom: 4px;
    text-wrap: wrap;
}

// the following css includes logic to show certain no of cards based on screen size
// it uses nth-child to to show certain no of cards and hide others
.match-budget-cars {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    > :nth-child(n-2) {
        display: block;
    }
    > :nth-child(n + 2) {
        display: none;
    }

    // display 2 cards for width min 640px
    @media screen and (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
        > :nth-child(n-3) {
            display: block;
        }
        > :nth-child(n + 3) {
            display: none;
        }
    }

    // display 3 cards for width min 1024px
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        > :nth-child(n-4) {
            display: block;
        }
        > :nth-child(n + 4) {
            display: none;
        }
    }

    // display 4 cards for width min 1400px
    @media screen and (min-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
        > :nth-child(n-5) {
            display: block;
        }
        > :nth-child(n + 5) {
            display: none;
        }
    }
}

.match-budget-detail-link {
    @include section-detail-link();
    display: none;

    @media screen and (min-width: 900px) {
        display: flex;
        text-wrap: nowrap;
    }
}
