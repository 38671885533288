.car-list-section {
    padding: 24px 0;
    background-color: $light-white;

    .car-list-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        align-items: center;

        .car-list-header-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: $black;
        }

        .car-list-header-sort {
            display: flex;
            align-items: center;
            gap: 6px;
            flex-wrap: wrap;
            justify-content: end;

            .sort-title {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: $light-black;
                width: max-content;
            }

            .car-list-sort-select {
                border-radius: 5px;
                border: 1px solid $grey;
                color: $main-blue;
                font-size: 16px;
                line-height: 22px;

                // Ant style override
                .ant-select-selection-item {
                    font-weight: 700;
                }
            }
        }
    }

    .car-list-grid {
        display: grid;
        gap: 12px;
        row-gap: 8px;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 14px;
            row-gap: 10px;
        }

        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 16px;
            row-gap: 12px;
        }
    }
}
