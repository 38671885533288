.tracker-layout {
    margin-top: 20px;
    margin-top: 20px;
    margin-bottom: 56px;
    font-family: Sofia-Pro;

    .cover-img {
        height: 520px;
        height: 520px;
        width: 100%;

        @media (max-width: 768px) {
            height: 250px;
        }
        img {
            object-fit: cover;
            border-radius: 5px;
        }
    }

    .tracker-header {
        margin: 0 10%;
        margin-top: -60px;
        background: #fff;
        position: relative;
        padding: 24px;
        border-radius: 5px;

        @media (max-width: 768px) {
            margin: 0 4%;
            margin-top: -60px;
        }

        h3 {
            font-weight: 700;
            font-size: 32px;
            @media (max-width: 768px) {
                font-size: 28px;
            }
        }
        p {
            color: #595959;
            font-size: 16px;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
    }

    .tracker-content-container {
        margin: 0 20%;
        @media (max-width: 768px) {
            margin: 0;
        }

        .tracker-table {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            border: 1px solid #f2f2f2;
            border-radius: 5px;
            padding: 16px;

            @media (max-width: 768px) {
                margin-top: 20px;
            }

            hr {
                border: 1px solid #f2f2f2;
                margin-top: 16px;
                margin-bottom: 16px;
            }

            .tracker-heading {
                font-size: 16px;
                font-weight: 700;

                display: flex;
                align-items: center;
                gap: 6px;
            }
            .tracker-table-header {
                background: #f5f8ff;
                padding: 12px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: bold;
                width: 100%;
                border: 1px solid #f2f2f2;

                span {
                    font-size: 14px;
                    font-weight: 400;
                }
                h6 {
                    font-weight: 700;
                    font-size: 16px;
                    margin-bottom: 0;
                    line-height: 19.2px;
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }

            .table-container {
                width: 100%;
                -webkit-overflow-scrolling: touch;
                position: relative;
                max-height: 400px;
                overflow-y: auto;

                @media (max-width: 768px) {
                    overflow-x: auto;
                }

                table {
                    border: none;
                    border-collapse: separate;
                    border-spacing: 0 12px;
                    width: 100%;

                    @media (max-width: 768px) {
                        min-width: 300px;
                        overflow-x: auto;
                    }
                    th:not(:first-child) {
                        padding-right: 20px;
                    }

                    td:first-child,
                    th:first-child {
                        border-radius: 10px 0 0 10px;
                    }

                    td:last-child,
                    th:last-child {
                        border-radius: 0 10px 10px 0;
                    }

                    th,
                    td {
                        text-align: left;
                        color: #212121;
                    }

                    th {
                        font-weight: 400;
                        font-size: 14px;
                        text-transform: uppercase;
                        @media (max-width: 768px) {
                            font-size: 14px;
                        }
                    }

                    th:not(:first-child) {
                        text-align: right;
                    }
                    thead {
                        tr {
                            position: sticky;
                            top: 0;
                            background-color: #fff;
                            th {
                                padding: 10px 0;
                            }
                        }
                    }
                    tbody {
                        .unit-style {
                            color: #8b8b8b;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        tr:first-child {
                            background-color: #f5f8ff;
                        }

                        tr {
                            border: 1px solid #f2f2f2;
                            font-size: 14px;
                            border-radius: 5px;
                            overflow: hidden;
                            td {
                                padding: 16px;
                                border: 1px solid #f2f2f2;

                                img {
                                    height: 15px;
                                    width: 21px;
                                    border-radius: 4px;
                                    margin-right: 6px;
                                    object-fit: cover;
                                }

                                @media (max-width: 768px) {
                                    font-size: 14px;
                                }
                            }
                        }
                        tr td:first-child {
                            border-right: none;
                            font-size: 16px;
                            color: #454545;
                            @media (max-width: 768px) {
                                font-size: 14px;
                            }
                        }

                        tr td:last-child {
                            border-left: none;
                        }

                        tr td:not(:first-child):not(:last-child) {
                            border: none;
                            border-top: 1px solid #f2f2f2;
                            border-bottom: 1px solid #f2f2f2;
                        }

                        tr td:not(:first-child) {
                            text-align: right;
                            font-size: 20px;
                            color: #212121;
                            font-weight: 700;
                            @media (max-width: 768px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    .converter-ui-container {
        padding: 0 calc(10% + 24px);

        @media (max-width: 768px) {
            padding: 0 calc(5% + 24px);
        }
        .converter-ui {
            display: flex;
            width: 100%;
            gap: 16px;
            align-items: center;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 5px;
            }

            .ant-input-affix-wrapper {
                border-radius: 5px;
                background-color: #f5f8ff;
                border: 1px solid #f2f2f2;
            }

            .ant-input-group.ant-input-group-compact .ant-input {
                width: calc(100% - 110px);
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            input {
                border-right: none;
                font-size: 20px;
                font-weight: 700;
            }
            input:hover {
                border-color: #d9d9d9;
            }
            input,
            .ant-select {
                height: 56px;
                background-color: #f5f8ff;
            }

            .ant-select-arrow {
                color: #000 !important;
                width: 16px;
            }

            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                border-left: none;
            }

            .ant-select:not(.ant-select-customize-input)
                .ant-select-selector:hover {
                border-color: #d9d9d9;
            }
            .ant-input-group.ant-input-group-compact > *:last-child,
            .ant-input-group.ant-input-group-compact
                > .ant-select:last-child
                > .ant-select-selector,
            .ant-input-group.ant-input-group-compact
                > .ant-cascader-picker:last-child
                .ant-input,
            .ant-input-group.ant-input-group-compact
                > .ant-cascader-picker-focused:last-child
                .ant-input {
                border-top-right-radius: 5px !important;
                border-bottom-right-radius: 5px !important;
            }
            .ant-select {
                width: 110px;
                .ant-select-selector {
                    background-color: #f5f8ff;
                }
                .ant-select-selection-item {
                    display: flex;
                }
                .phone-label {
                    height: 16px;
                    width: 22px;
                    align-items: center;
                    img {
                        margin-right: 5px;
                    }
                }
            }
        }

        .updated-date {
            font-size: 14px;
            margin-top: 16px;
        }
    }
}

.calculator-ui-container {
    padding: 0 calc(10% + 24px);

    @media (max-width: 768px) {
        padding: 0 calc(5% + 24px);
    }
    .calculator-ui {
        display: flex;
        gap: 16px;
        flex-direction: row;
        align-items: stretch;

        .calc-form,
        .calc-result {
            flex: 1;
            min-width: 50%;
        }

        .calc-form {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .calc-form-row {
                display: flex;
                gap: 20px;

                @media (max-width: 500px) {
                    flex-direction: column;
                }
                .custom-select {
                    padding: 12px;
                    height: 56px;
                    width: 100%;
                    display: flex;
                    gap: 6px;
                    align-items: center;
                    border: 1px solid #f2f2f2;
                    background-color: #f5f8ff;
                    border-radius: 5px;
                    overflow: hidden;

                    .cus-icon {
                        width: 20px;
                        height: 20px;
                    }
                    .ant-select {
                        width: 100%;
                        font-size: 20px;
                        font-family: Sofia-Pro;
                        .ant-select-selector {
                            padding: 0;
                            border: none;
                            background-color: transparent;
                            box-shadow: none;

                            .ant-select-selection-item {
                                font-size: 20px !important;
                                font-family: Sofia-Pro;
                                font-weight: 700;
                            }
                        }
                        .ant-select-arrow {
                            right: -1px !important;
                        }
                    }
                    .ant-select-focused {
                        border: none;
                    }
                    .ant-select-selection-item {
                        text-transform: capitalize;
                        color: #212121;
                    }
                }
                .ant-select-selection-item {
                    text-transform: capitalize;
                    color: #8b8b8b;
                }
                .ant-space {
                    width: 100%;
                    gap: 4px !important;
                }
                label {
                    color: #8b8b8b;
                    font-size: 13px;
                    font-family: Sofia-Pro;
                }

                .slider-label {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    gap: 2px;

                    @media screen and (min-width: 768px) {
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .slider-label-header {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: #333333;
                    }

                    .slider-label-value {
                        border-radius: 5px;
                        border: 1px solid $grey;
                        padding: 7px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 19px;
                    }
                }

                .loan-calculator-slider {
                    .ant-slider-rail {
                        background-color: #d8d8d8;
                        height: 6px;
                        border-radius: 5px;
                    }

                    .ant-slider-track {
                        background-color: #012367;
                        height: 6px;
                        border-radius: 5px;
                    }
                    .ant-slider-handle {
                        height: 19px;
                        width: 19px;
                        border: none;
                        box-shadow: 0px 0.8px 2.39px 0px #0000004d;
                    }
                }

                .ant-radio-button-wrapper:not(:first-child)::before {
                    content: none !important;
                }

                .loan-calculator-form-item.tenure-year {
                    .ant-form-item {
                        margin-bottom: 0px;
                    }
                }

                .car-loan-form-slider {
                    margin-bottom: 4px;

                    .loan-calculator-slider {
                        .ant-slider-rail {
                            background-color: #d8d8d8;
                            height: 6px;
                            border-radius: 5px;
                        }

                        .ant-slider-track {
                            background-color: #012367;
                            height: 6px;
                            border-radius: 5px;
                        }
                        .ant-slider-handle {
                            height: 19px;
                            width: 19px;
                            border: none;
                            box-shadow: 0px 0.8px 2.39px 0px #0000004d;
                        }
                    }
                }

                .car-loan-tenure-radio-group {
                    display: flex;
                    gap: 8px;

                    .tenure-button {
                        border-radius: 5px;
                        border: 1px solid $grey;
                        padding: 7px;
                        flex-grow: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px !important;
                        line-height: 18px;
                        height: auto !important;
                    }
                    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
                        background-color: #012367;
                        box-shadow: none;
                        span {
                            color: #fff;
                        }
                    }
                }

                .ant-input-group-wrapper,
                .ant-input-affix-wrapper {
                    border: 1px solid #f2f2f2;
                    background-color: #f5f8ff;
                    border-radius: 5px;
                    overflow: hidden;
                    .ant-input-affix-wrapper,
                    .ant-input-group,
                    .ant-input-group-addon {
                        height: 56px;
                        border: none;
                        background-color: transparent;
                        color: #212121;
                        font-weight: 700;
                    }
                    .ant-input-prefix {
                        margin-right: 6px;
                    }
                    .ant-input {
                        background-color: transparent;
                        font-size: 20px;
                        font-family: Sofia-Pro;
                        font-weight: 700;
                    }
                }

                .ant-select-arrow {
                    color: #000 !important;
                    width: 16px;
                }
            }
        }

        .calc-result {
            border: 16px solid #f5f9ff;
            padding: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            align-self: stretch;
            height: auto;
            border-radius: 5px;

            h6,
            h3 {
                font-family: Sofia-Pro;
            }

            h6 {
                color: $dark-blue;
                font-size: 16px;
                margin-bottom: 0;
            }
            h3 {
                font-size: 32px;
                font-weight: 700;
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;

            .calc-form,
            .calc-result {
                min-width: 100%;
            }
        }
    }
}

.all-tools-container {
    margin-top: 56px;
    margin-bottom: 56px;

    h4 {
        margin-bottom: 24px;
        font-size: 24px;
        font-family: Sofia-Pro;
        font-weight: 700;
    }

    .tools-card-wrapper {
        flex-wrap: wrap;
        display: flex;
        gap: 20px;

        .tool-link-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
            // flex: 1 1 30%;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            background-color: #f5f8ff;
            min-width: calc(33.33% - 14px);
            transition: box-shadow 0.3s ease;

            &:hover {
                // box-shadow: 0 5px 10px $dark-blue-tint4;
                box-shadow: $dark-blue-tint4 0px 2px 6px;
                // box-shadow: $dark-blue-tint4 0px -20px 16px -18px inset;
                box-shadow: 0px 0px 10px #175adc7a;
            }

            @media (max-width: 1024px) {
                min-width: calc(50% - 14px);
            }

            @media (max-width: 768px) {
                min-width: 100%;
            }

            .tools-desc {
                display: flex;
                gap: 16px;
                align-items: center;
                img {
                    height: 40px;
                    width: 40px;
                }

                span {
                    font-family: Sofia-Pro;
                    font-size: 16px;
                    font-weight: 700;
                    color: #212121;
                }
            }
            .tools-arrow {
                height: 16px;
                width: 16px;
                color: #212121;
                transition: transform 0.3s ease;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }
            &:hover .tools-arrow {
                transform: translateX(5px);
            }

            @media (max-width: 768px) {
                flex: 1 1 100%;
            }
        }

        .tool-link-card-disabled {
            &:hover {
                box-shadow: none;
                cursor: default;
            }
            &:hover .tools-arrow {
                transform: none;
            }
        }
    }
}

.tracker-layout-cars {
    background-color: #fafafa;
}

.calculator-options {
    text-transform: capitalize;
    min-width: fit-content !important;
}

.tracker-content-data {
    table {
        tr {
            th,
            td {
                padding: 5px 10px;
            }
        }
    }
}

.converter-options {
    text-transform: capitalize;
}

.tracker-content {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .tracker-content-data {
        h1,
        h2,
        h3 {
            font-weight: 700;
            font-family: Sofia-Pro;
            color: #212121;
        }
        h1 {
            font-size: 28px;
            line-height: 36px;
            padding: 14px 0;
            font-family: Sofia-Pro;
        }
        h2 {
            font-size: 24px;
            line-height: 30px;
            padding: 10px 0;
        }
        h3 {
            font-size: 18px;
            line-height: 26px;
            padding: 8px 0;
        }
        p {
            font-size: 14px;
            color: #595959;
            line-height: 19.6px;
            padding-bottom: 10px;
        }
        ul {
            color: #595959;
            margin-left: 24px;
            list-style: none;
            font-size: 14px;
            font-family: Sofia-Pro;

            li {
                color: #595959;
                margin-bottom: 4px;
                font-family: Sofia-Pro;
            }
        }
        ol {
            color: #595959;
            margin-left: 24px;
            font-size: 14px;
            font-family: Sofia-Pro;

            li {
                color: #595959;
                padding-bottom: 4px;
            }
        }
        table {
            min-width: 300px;
            border-collapse: collapse;
            padding-bottom: 20px;
            tr {
                font-size: 14px;
                td,
                th {
                    text-align: left;
                    padding: 5px 14px;
                    border: 1px solid #ddd;
                }
            }
        }
    }
}
