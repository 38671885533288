@font-face {
    font-family: Sofia-Pro;
    font-weight: 400;
    src: url(../../fonts/Sofia-Pro-Regular-Az.woff);
}
@font-face {
    font-family: Sofia-Pro;
    font-weight: 500;
    src: url(../../fonts/Sofia-Pro-Regular-Az.woff);
}
@font-face {
    font-family: Sofia-Pro;
    font-weight: 700;
    src: url(../../fonts/Sofia-Pro-Bold-Az.woff);
}