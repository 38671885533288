.star-rating-container {
    font-weight: 700;
    font-size: 25px;
    position: relative;
    top: 5px;

    .active {
        color: #FFB040;
    }

    .inactive {
        color: $dark-gray;
    }
}

