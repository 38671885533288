.news-letter {
    @include flex-center;
    flex-direction: column;
    padding: 64px 0 64px;
    background-color: $light-white;

    .title {
        margin-bottom: 8px;
    }
    .subtitle {
        text-align: center;
        margin-bottom: 32px;
    }
    .email-send {
        @include flex-center;
        gap: 16px;
        width: 90%;
        margin-inline: auto;
        @media screen and (min-width: 768px) {
            gap: 24px;
            width: 70%;
        }
        @media screen and (min-width: 1024px) {
            width: fit-content;
        }
        .email {
            height: 50px;
            border: 1px solid #b3b3b3;
            border-radius: 5px;
            padding: 14px;
            width: 100%;
            @include flex-center;
            gap: 10px;
            margin-inline: auto;
            background-color: $extra-white;
            @media screen and (min-width: 1024px) {
                width: 360px;
            }
            .envelope {
                margin-top: 5px;
            }
            input {
                width: 100%;
                background-color: transparent;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: $dark-gray;
                cursor: text;
                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }
            input::placeholder {
                text-align: start;
                @include flex-center;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: $light-medium-b2;
                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }
        .bottom-btn {
            width: auto;
            height: 50px;
            width: 86px;
        }
    }
}
