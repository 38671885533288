* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import 'antd/dist/antd.css';

body {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    font-family: 'Sofia-Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

button {
    outline: none;
    border: none;
    cursor: pointer;
}

input {
    outline: none;
    border: none;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h5 {
    margin: 0;
}

p {
    padding: 0;
    margin: 0;
}

.padding {
    padding: 0 16px;
}

.n-margin {
    margin: 0 -15px;
}

.loading-container {
    min-height: 60vh;
    display: grid;
    place-items: center;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #091731;
    margin-bottom: 8px;
}

.subtitle {
    font-style: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #595959;
    width: 75%;
    margin: 0 auto;
    margin-bottom: 22.4px;
}

.bottom-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    min-width: 200px;
    max-width: 320px;
    min-height: 50px;
    background: #175adc;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
    cursor: pointer;
}

.bottom-btn:hover {
    background: #07348d;
    box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
}

.white-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    color: #175adc;
    border: 1px solid #175adc;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 140%;
    background: #ffffff;
    cursor: pointer;
}

.white-btn:hover {
    background: #dee6f7;
    border: 1px solid #175adc;
}

.button-disabled {
    cursor: not-allowed !important;
    background-color: #3b77eb !important;
}

.loan-btn {
    background: #091731;
    font-weight: 400;
    cursor: pointer;
    width: auto;
    padding: 16px;
    height: 50px;
}

.mySwiper.swiper-button-prev {
    display: none !important;
}

.mySwiper.swiper-button-next {
    display: none !important;
}

.category {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #1f335a;
    margin-bottom: 16px;
}

input {
    cursor: text;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #454545;
    box-shadow: 0 0 0px 1000px #f5f8ff inset;
    -webkit-box-shadow: 0 0 0px 1000px #f5f8ff inset;
    transition: background-color 5000s ease-in-out 0s;
}

input[type='number'] {
    -moz-appearance: textfield;
}

textarea {
    resize: none;
}

.terms-condt {
    display: block;
    font-size: 16px;
    line-height: 1.4;
    color: #727272;
}

.termandpolicy {
    text-decoration: underline;
    color: #175adc;
}

@media (max-width: 720px) {
    .termandpolicy {
        display: inline;
    }
}

@media screen and (min-width: 768px) {
    .title {
        font-size: 25px;
        text-align: start;
    }

    .center {
        text-align: center;
    }

    .subtitle {
        font-size: 16px;
        text-align: start;
        width: 100%;
    }

    .category {
        font-size: 20px;
        line-height: 140%;
    }

    .padding {
        padding: 0 40px;
    }

    .n-margin {
        margin: 0 -40px;
    }
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #175adc;
}

@media screen and (min-width: 1024px) {
    .title {
        font-size: 31px;
    }

    .start {
        text-align: start;
        margin-bottom: 24px;
    }

    .mySwiper.swiper-button-prev {
        display: flex !important;
        align-items: center;
        color: #454545;
        background-color: #fff;
        border-radius: 20px;
        font-size: 10px !important;
        height: 40px;
        width: 40px;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
    }

    .mySwiper.swiper-button-next {
        display: flex !important;
        align-items: center;
        color: #454545;
        background-color: #fff;
        border-radius: 20px;
        font-size: 10px !important;
        height: 40px;
        width: 40px;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
        top: 90px;
    }

    .mySwiper.swiper-button-next:after {
        font-size: 16px;
        font-weight: 700;
    }

    .mySwiper.swiper-button-prev:after {
        display: block;
        font-size: 16px;
        font-weight: 700;
    }

    .mySwiper.swiper-button-prev.swiper-button-disabled {
        display: none !important;
    }

    .mySwiper.swiper-button-next.swiper-button-disabled {
        display: none !important;
    }
}

@media screen and (min-width: 1200px) {
    .padding {
        padding: 0 40px;
    }

    .n-margin {
        margin: 0 -40px;
    }
}

@media screen and (min-width: 1440px) {
    .padding {
        padding: 0;
        max-width: 1280px;
        margin-inline: auto;
        width: 100%;
    }
}

@media screen and (min-width: 1500px) {
    .padding {
        max-width: 1440px;
    }
}

/*@media screen and (min-width: 1441px) {*/
/*    .padding {*/
/*        max-width: 1440px;*/
/*    }*/
/*}*/

.text-uppercase {
    text-transform: uppercase;
}

.row {
    display: flex;
}

@media screen and (min-width: 1200px) {
    .big-font {
        font-size: 20px;
    }
}

.button-white {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    min-width: 149px;
    max-width: 320px;
    min-height: 44px;
    background: #ffffff;
    color: #175adc;
    border: 1px solid #175adc;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}

.font-weight-700 {
    font-weight: 700;
}

.user-member {
    display: flex;
    align-items: start;
    margin-top: 10px;
}

.pic {
    overflow: hidden;
    height: 40px;
    width: 40px;
    /* Increased image size */
    border-radius: 50%;
    flex-shrink: 0;
}

.font-weight-400 {
    font-weight: 400;
}

.img-fluid {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

.span-text {
    font-family: Outfit;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
}

/*css*/

.sec-seperator {
    width: 100px;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 8px;
}

.sec-seperator .seperator-first {
    width: 50%;
    border: 2px solid blue;
    /* Replace $main-blue with actual color */
}

.sec-seperator .seperator-second {
    width: 50%;
    border: 2px solid darkblue;
    /* Replace $main-dark-blue with actual color */
}

.left-align {
    margin-inline: 0;
}

@media screen and (min-width: 768px) {
    .sec-seperator {
        width: 110px;
        margin: 0;
        margin-bottom: 12px;
    }
}

@media screen and (min-width: 1024px) {
    .sec-seperator {
        width: 130px;
    }
}

@media screen and (min-width: 768px) {
    .mobile-btn {
        display: none;
    }
}

/* override for react-lightroom active thumbnail */
.lg-outer .lg-thumb-item.active{
    border-color: #175adc !important;
}