@import './sectionutils';

.section-main-container {
    padding: 56px 0px;

    @media (max-width: 768px) {
        padding: 32px 0px;
    }
}

.section-container {
    width: 100%;
    margin: 0 auto;
}

.section-container-heading-row {
    @include section-container-heading-row(center);
}

.section-container-title {
    @include section-container-title();
    display: inline;
}

.section-container-detail-link {
    @include section-detail-link();
}
