.best-seller-hero {
    background-color: $dark-blue;
    height: 590px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 36px;
    @media (max-width: 568px) {
        height: 460px;
    }

    img {
        width: 100%;
        height: 272px;
        object-fit: contain;
        margin-top: 40px;
        @media (max-width: 568px) {
            height: auto;
            margin-top: 20px;
        }
    }

    .best-seller-hero-content {
        text-align: center;
        h6 {
            color: #fff;
            font-size: 20px;
            font-family: Sofia-Pro;
            font-weight: 700;
        }
        h2 {
            font-size: 40px;
            color: #fff;
            font-family: Sofia-Pro;
            font-weight: 700;
            line-height: 45px;
        }
        span {
            font-size: 16px;
            color: #fff;
            font-family: Sofia-Pro;
            font-weight: 700;
        }
    }
}

.best-selling-container {
    display: flex;
    gap: 40px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    padding: 40px !important;
    max-width: 1064px;
    margin: 56px auto;

    @media (max-width: 1024px) {
        flex-direction: column;
    }

    @media (max-width: 568px) {
        padding: 0 !important;
        margin: 30px auto;
        border: none;
    }

    .best-selling-showcase {
        display: flex;
        width: 100%;
        justify-content: center;

        @media (max-width: 568px) {
            display: none;
        }

        .best-selling-bar-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            justify-content: flex-end;
            width: 154px;

            .best-selling-bar-header {
                display: flex;
                flex-direction: column;
                gap: 16px;
                align-items: center;
                .best-vehicle-img {
                    height: 120px;
                    width: 120px;
                    align-items: center;
                    justify-content: center;
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
                .best-selling-desc {
                    text-align: center;

                    h4 {
                        font-size: 16px;
                        font-weight: 700;
                        font-family: Sofia-Pro;
                        color: #454545;
                    }
                    .position-icon {
                        margin-right: 8px;
                    }
                    .sold-number {
                        color: #009e60;
                        font-family: Sofia-Pro;
                        font-weight: 700;
                        font-size: 14px;
                    }
                    span {
                    }
                }
            }
            .best-selling-bar {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                overflow: hidden;
                display: flex;
                align-items: end;
                justify-content: center;
                width: 100%;

                img {
                    object-fit: contain;

                    @media (max-width: 568px) {
                        width: 100% !important;
                    }
                }
            }
            .best-selling-bar-1 {
                height: 182px;
                background-color: #193d6d;
                padding-bottom: 30px;

                img {
                    height: 65px;
                    width: 65px;
                }
            }
            .best-selling-bar-2 {
                height: 260px;
                background-color: #0a2445;
                padding-bottom: 20px;

                img {
                    height: 100px;
                    width: 100px;
                }
            }
            .best-selling-bar-3 {
                height: 122px;
                background-color: #193d6d;
                padding-bottom: 30px;

                img {
                    height: 60px;
                    width: 60px;
                }
            }
        }
    }
    .best-selling-showcase-sm {
        display: none;

        @media (max-width: 568px) {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .best-selling-bar-container {
            display: flex;
            gap: 12px;
            .best-selling-bar {
                height: 65px;
                border-radius: 8px;
                padding: 12px;
                display: flex;
                align-items: center;

                .best-selling-desc {
                    h4,
                    span {
                        color: #fff;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        line-height: 18px;
                    }
                    h4 {
                        font-weight: 700;
                        font-family: Sofia-Pro;
                        font-size: 16px;
                        margin-bottom: 4px;
                    }
                    .icon-sold {
                        display: flex;
                        gap: 6px;
                        align-items: center;
                        .sold-number {
                            font-size: 14px;
                            font-family: Sofia-Pro;
                        }
                    }
                }
            }
            .best-selling-bar-1-sm {
                width: calc(100% - 56px);
                background-color: #0a2445;
            }
            .best-selling-bar-2-sm {
                width: calc(90% - 56px);
                background-color: #193d6d;
            }
            .best-selling-bar-3-sm {
                width: calc(80% - 56px);
                background-color: #193d6d;
            }

            .best-vehicle-img {
                display: flex;
                align-items: center;

                img {
                    height: 50px;
                    width: 50px;
                    object-fit: contain;
                }
            }
        }
    }
    .best-selling-list {
        display: flex;
        gap: 16px;
        flex-direction: column;
        width: 100%;

        h2 {
            font-family: Sofia-Pro;
            font-weight: 700;
            font-size: 20px;
        }

        .best-selling-card-group {
            display: flex;
            gap: 16px;
            flex-direction: column;
            width: 100%;
            overflow-x: auto;

            .best-selling-card {
                border: 1px solid #f2f2f2;
                padding: 16px;
                display: flex;
                justify-content: space-between;
                min-width: 100%;
                border-radius: 5px;

                // @media (max-width: 568px) {
                //     min-width: 480px;
                // }

                .list-number {
                    display: flex;
                    gap: 16px;
                    align-items: center;
                    img {
                        object-fit: contain;
                    }
                    .list-brand-sold {
                        .list-sold-number {
                            display: none;

                            @media (max-width: 568px) {
                                display: flex;
                                gap: 13px;
                                align-items: center;
                            }
                            span {
                                color: #454545;
                                font-weight: 400;
                            }
                        }
                    }
                    .list-number-img,
                    h2 {
                        height: 32px;
                        width: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 700;
                        font-family: Sofia-Pro;
                        font-size: 20px;
                        color: #454545;
                    }
                    .list-brand-logo {
                        height: 40px;
                        width: 40px;
                    }
                    span {
                        font-size: 16px;
                        font-family: Sofia-Pro;
                        font-weight: 700;
                        color: #454545;
                    }
                }
                .list-sold-number {
                    display: flex;
                    gap: 13px;
                    align-items: center;
                    @media (max-width: 568px) {
                        display: none;
                    }
                    span {
                        color: #454545;
                    }
                }
            }
        }
    }
}

.best-selling-cars-content {
    max-width: 1064px;
    margin: auto;
}
