@mixin input-filed {
    border-radius: 5px;
    border: 1px solid $medium-white;
    padding: 9px 12px;
    font-size: 14px;
    line-height: 19px;
    color: #464646;

    &::placeholder {
        color: $medium-grey;
    }
}

@mixin selection-button {
    border: 1px solid $light-white;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: $light-white;
    font-size: 14px;
    line-height: 18px;

    &.active {
        border-color: $main-blue;
        color: $main-blue;
    }
}

.car-list-filter-section {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 24px 0;

    .car-list-filter-search-field {
        position: relative;
        flex-grow: 1;
        grid-column: 2 span;

        .car-list-search-field {
            border-radius: 5px;
            border: 1px solid $medium-white;
            padding: 10px 12px;
            font-size: 14px;
            line-height: 19px;
            color: #464646;

            &::placeholder {
                color: $medium-grey;
            }

            width: 100%;
        }

        .input-field-icon {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    .car-filter-item {
        flex-grow: 1;
        max-width: 198px;
        min-width: 160px;
        @media screen and (max-width: 425px) {
            min-width: 100%;
            max-width: 100%;
        }
    }

    .more-car-filter-btn {
        border-radius: 5px;
        padding: 9px 16px;
        border: 1px solid #e6e6e6;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #464646;
        background-color: transparent;
        font-size: 14px;
        line-height: 19px;
        grid-column: 2 span;
    }
}

.more-filter-container {
    .more-filter {
        position: fixed;
        background-color: #21212180;
        height: 100vh;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 2000;

        .filter-card {
            width: 100%;
            max-width: 440px;
            background-color: #ffffff;
            border: 5px;
            padding: 20px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;

            .filter-scroll-section {
                overflow: scroll;
                height: 100%;
                position: relative;

                .more-filter-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    position: sticky;
                    top: 0;
                    background-color: #ffffff;

                    .more-filter-header-title {
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 28px;
                        color: $black;
                    }

                    .more-filter-close-btn {
                        cursor: pointer;
                        background-color: transparent;
                    }
                }
            }

            .expanded-filter {
                margin-bottom: 99px;
            }

            .filter-action {
                width: 100%;
                position: absolute;
                background-color: #ffffff;
                bottom: 0;
                right: 0;
                padding: 24px 16px;
                border-bottom: 5px;
                display: flex;
                gap: 24px;
                border-top: 1px solid $grey;
                box-shadow: 0px -2px 20px 0px #0000000d;

                .clear-all-btn {
                    padding: 14px 16px;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 22px;
                    color: $main-dark-blue;
                    text-wrap: nowrap;
                    border-radius: 5px;
                    background: transparent;
                }

                .apply-filter-btn {
                    flex-grow: 1;
                    background-color: $main-blue;
                    padding: 14px 16px;
                    text-align: center;
                    color: #ffffff;
                    border: 5px;
                    border-radius: 5px;
                    font-weight: 700;
                }
            }
        }
    }

    .expand-filter-section-divider {
        margin: 20px 0;
        border: 0;
        height: 1px;
        background-color: $medium-white;
    }
}

.filter-expand-container {
    .filter-expanded-header {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filter-expand-title {
            color: $light-black;
            font-size: 16px;
            line-height: 22px;
        }

        .filter-expand-btn {
            cursor: pointer;
            background-color: transparent;
        }
    }

    // filters
    .expanded-filter-transmission {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .transmission-filter-btn {
            @include selection-button();
        }
    }

    .expanded-filter-body-type {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        row-gap: 12px;

        .body-type-card {
            cursor: pointer;
            display: flex;
            gap: 16px;
            flex-direction: column;
            border: 1px solid transparent;
            border-radius: 5px;
            padding: 2px;

            &.active {
                border-color: $main-blue;
            }

            img {
                object-fit: contain;
            }

            .body-type-name {
                font-size: 14px;
                line-height: 18px;
                color: $medium-black;
                text-align: center;
            }
        }
    }

    .expanded-filter-fuel-type {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        row-gap: 8px;

        .fuel-selection-btn {
            @include selection-button();
            border: 1px solid transparent;

            &.active {
                border-color: $main-blue;
            }
        }
    }

    .expanded-filter-color-type {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        row-gap: 24px;
        align-items: center;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
        }

        .color-item {
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: transparent;
            cursor: pointer;
            border: 1px solid transparent;
            padding: 2px;
            border-radius: 5px;

            &.active {
                border-color: $main-blue;
            }

            .colored-circle {
                border-radius: 50%;
                width: 28px;
                height: 28px;
                border: 1px solid #dedede;
            }

            .color-name {
                font-size: 14px;
                line-height: 19px;
                color: $light-black;
            }
        }
    }

    .expanded-filter-model-year {
        padding: 12px;
        border-radius: 5px;
        border: 1px solid $medium-white;

        .car-model-slider {
            margin-bottom: 17px;

            .ant-slider-rail {
                background-color: #d8d8d8;
                height: 6px;
                border-radius: 5px;
            }

            .ant-slider-track {
                background-color: #012367;
                height: 6px;
                border-radius: 5px;
            }

            .ant-slider-handle {
                height: 19px;
                width: 19px;
                border: none;
                box-shadow: 0px 0.8px 2.39px 0px #0000004d;
            }
        }

        .expanded-filter-input-fields {
            display: flex;
            gap: 8px;
            align-items: center;

            .year-range-divide {
                border: none;
                background-color: $medium-grey;
                width: 16px;
                height: 1px;
            }

            .selects-container {
                flex-grow: 1;
            }

            .year-range {
                @include input-filed();
                // override for inner select css
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

.car-filter-select-field {
    @include input-filed();
    // override for inner select css
    background-color: #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .placeholder-text {
        color: $medium-grey;
    }
}

.ant-select-dropdown {
    z-index: 2000 !important;
}

.ant-tooltip {
    z-index: 2000 !important;
}

.select-ant-override {
    height: 40px;
}
