.new-password-form {
    margin-top: 8px;
    .form-text {
        display: block;
        color: $light-black;
        @include regular-font;
        margin-bottom: 32px;
    }
    .text-box-wrapper {
        margin-bottom: 24px;
        .text-box-info {
            display: block;
            color: $dark-gray;
            font-size: 14px;
            line-height: 1.4;
            margin-top: 8px;
        }
        .error-text {
            color: $error-color;
        }
        .success-text {
            color: #008a22;
        }
    }
    .button {
        padding: 14px;
    }
    .sign-in-redirect {
        color: $dark-gray;
        @include regular-font;
        margin-top: 16px;
        display: block;
        span {
            color: $main-blue;
            font-size: 16px;
            line-height: 1.4;
            cursor: pointer;
            font-weight: 700;
        }
    }
}
