.search-by-brand-type-section {
    background-color: white;
}

.brand-type-slider {
    .swiper-slide {
        background-color: transparent;
    }
}

.brand-type-slider-card {
    cursor: pointer;

    img {
        aspect-ratio: 1/1;
        object-fit: contain;
    }

    .body-type-slider-text {
        margin-top: 8px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: $medium-black;
        line-height: 18px;
    }
}
