.sell-details {
    width: 90%;
    margin: 0 auto;
    background-color: $white;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    z-index: 20;
    @media screen and (min-width: 767px) {
        width: 80%;
        margin-inline: auto;
        // transform: translate(0, -68px);
        transform: translate(0, -120px);
    }
    @media screen and (min-width: 1024px) {
        width: 48.62%;
        // position: absolute;
        // top: 0;
        // left: 50%;
        // transform: translate(-50%, -68px);
        transform: translate(0, -120px);
    }
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
    }
    .bottom-btn {
        margin: auto;
    }
    .bottom-btn:disabled {
        cursor: no-drop;
    }

    .sell-details-tab {
        .ant-tabs-nav {
            margin-bottom: 0;
            .ant-tabs-nav-wrap {
                .ant-tabs-nav-list {
                    width: 100% !important;
                    margin: 0;
                    height: 62px;
                    @media screen and (min-width: 768px) {
                        height: 68px;
                    }
                    .ant-tabs-tab {
                        width: 50%;
                        justify-content: center;
                        border-bottom: 4px solid #b3bfd6;
                        margin: 0;
                        .ant-tabs-tab-btn {
                            @include regular-font;
                            text-align: center;
                            color: $medium-black;
                            @media screen and (min-width: 768px) {
                                font-size: 20px;
                            }
                        }
                        &.ant-tabs-tab-active {
                            border-bottom: 4px solid #000000;
                            border-radius: 9px 0 0 0;

                            background: #f5f8ff;
                            .ant-tabs-tab-btn {
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 140%;
                                text-align: center;
                                color: $main-dark-blue;
                                @media screen and (min-width: 768px) {
                                    font-size: 20px;
                                }
                            }
                        }
                        &.ant-tabs-tab-disabled {
                            border-radius: 0 9px 0 0;
                        }
                    }
                    .ant-tabs-ink-bar {
                        width: 50% !important;
                        display: none;
                    }
                    .ant-tabs-ink-bar-animated {
                        width: 50% !important;
                        background: #f5f8ff !important;
                        border-bottom: 4px solid #000000;
                        border-radius: 9px 0px 0px 0px;
                    }
                }
            }
            .ant-tabs-nav-wrap::after {
                display: none;
            }
            .ant-tabs-nav-operations {
                display: none !important;
            }
        }
        .ant-tabs-content-holder {
            padding: 20px;
            @media screen and (min-width: 768px) {
                padding: 32px;
            }
        }
    }
    .sell-top {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .sell-input {
            width: 100%;
            border: 1px solid $medium-grey;
            border-radius: 5px !important;
            height: 48px !important;
            @include flex-center;
            padding: 0 11px;
            margin-bottom: 24px;
            background-color: $extra-white;
            .icon {
                width: 22px;
                height: 20px;
            }
            input {
                border-radius: 5px !important;
                width: 100%;
                height: 100%;
                @include input-font;
                color: $dark-gray;
                padding-left: 12px !important;
                background-color: $extra-white;
            }
            input::placeholder {
                padding-top: 12px;
                @include input-font;
                color: $light-medium-b2;
                text-align: left;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus {
                border: none;
                box-shadow: 0 0 0px 1000px #f5f8ff inset;
                -webkit-box-shadow: 0 0 0px 1000px #f5f8ff inset;
                transition: background-color 5000s ease-in-out 0s;
            }
            input[type='number'] {
                -moz-appearance: textfield;
            }
        }
    }
    .sell-bottom {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 24px;
    }

    .personal-details {
        display: flex;
        flex-direction: row;
        padding: 12px;
        gap: 8px;
        width: 100%;
        background-color: $extra-white;
        border: 1px solid $medium-grey;
        border-radius: 5px;
        padding: 12px;
        height: 50px;
        flex: 1 10;
        input {
            width: 100%;
            @include input-font;
            color: $dark-gray !important;
            background-color: $extra-white;
            cursor: text;
        }
        input::placeholder {
            @include input-font;
            font-size: 14px;
            background-color: $extra-white;
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
        }

        /* Change Autocomplete styles in Chrome*/
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus input:-webkit-autofill,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-text-fill-color: $dark-gray;
            box-shadow: 0 0 0px 1000px #f5f8ff inset;
            -webkit-box-shadow: 0 0 0px 1000px #f5f8ff inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
    .sell-number {
        margin-bottom: 24px;
    }
}
.ant-modal {
    padding-bottom: 0 !important;
}
.sell-modal {
    @media screen and (min-width: 768px) {
        width: 430px !important;
    }
    .ant-modal-content {
        padding: 32px !important;
        border-radius: 10px;
        .ant-modal-header {
            border-radius: 10px;
            border: none;
            padding: 0;
            margin-bottom: 8px;
            .ant-modal-title {
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 140%;

                text-align: center;

                color: $main-dark-blue;
                border: none;
            }
        }
        .ant-modal-body {
            padding: 0;
            .subtitle {
                padding: 0;
                text-align: center;
            }
            .done-icon {
                width: 105px;
                height: 90px;
                margin: auto;
                margin-top: 36px;
                margin-bottom: 36px;
            }
        }

        .ant-modal-footer {
            display: none !important;
        }
        .modal-btn-container {
            display: flex;
            align-items: center;
            gap: 24px;
            button {
                width: calc((100% - 24px) / 2);
                @include flex-center;
                flex-direction: row;
                justify-content: center;
                padding: 8px 16px;
                height: 50px;
                @include regular-font;
                border-radius: 5px;
                box-shadow: none;
                text-shadow: none;
            }
            .explore {
                background: #175adc;
                border: none;
                color: $white;
            }
            .close {
                border: 1px solid #175adc;
                color: $main-blue;
                background-color: $white;
            }
        }
    }
}
.success-sell {
    .ant-modal-content {
        .ant-modal-footer {
            display: none;
        }
    }
}
