.footer-container {
    background-color: $dark-blue-tint1;
    .footer {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 32px;

        @media screen and (min-width: 768px) {
            display: flex;
            padding-top: 52px;
            padding-bottom: 50px;
            flex-direction: row;
        }

        @media screen and (max-width: 480px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        @media screen and (min-width: 1200px) {
            display: flex;
            gap: 55px;
            justify-content: space-between;
            flex-direction: row;
        }

        .footer-subtitle {
            margin-bottom: 16px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: $white;
            @media screen and (min-width: 768px) {
                font-size: 20px;
            }
        }
        .border-top {
            border: 1px solid $white;
            width: 40px;
            margin-bottom: 8px;
            height: 0;
            background-color: $white;
        }
        .footer-lists {
            list-style: none;
            font-style: normal;
            font-size: 14px;
            line-height: 140%;
            color: $white;
            @include flex-center;
            cursor: pointer;
            gap: 12px;
            flex-wrap: wrap;
            text-transform: capitalize;
            margin-bottom: 12px;
            // margin-right: 10px;
            a {
                color: $white;
            }
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
            &:last-child {
                @media screen and (min-width: 1024px) {
                    margin-bottom: 0;
                }
            }
            .footer-list {
                @include flex-center;
                .seperator {
                    border: 0.5px solid #ffffff;
                    margin-left: 12px;
                    height: 14px;
                    display: inline-block;
                }
                & > span {
                    transition: all 0.2s ease-in-out;
                    &:first-child {
                        &:hover {
                            color: #cccccc;
                        }
                    }
                }
            }
            .footer-list:last-child {
                .seperator {
                    display: none;
                }
            }
        }
        .footer-top {
            @media screen and (min-width: 1024px) {
                // width: 45%;
                margin-bottom: 60px;
            }
            @media screen and (min-width: 1200px) {
                width: 42%;
                margin-bottom: 0;
            }
            .footer-top-top {
                @include flex-center;
                gap: 24px;
                margin-bottom: 30px;
                .footer-logo-container {
                    width: 80px;
                    height: 75px;
                    @media screen and (min-width: 768px) {
                        width: 124px;
                        height: 124px;
                    }
                    & > img {
                        object-fit: contain;
                    }
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    color: $white;
                    width: 75%;
                    text-align: justify;
                    @media screen and (min-width: 768px) {
                        // width: 30%;
                        font-size: 16px;
                    }
                    @media screen and (min-width: 1024px) {
                        // width: 61%;
                    }
                }
            }
            .footer-top-available {
                .footer-top-btn-holder {
                    @include flex-center;
                    gap: 15px;
                    button {
                        @include flex-center;
                        background-color: $black;
                        color: #ffffff;
                    }
                }
            }
        }

        .footer-content {
            flex: 1;
            color: #ffffff;
            display: flex;
            justify-content: space-between;

            .footer-section {
                gap: 16px;
                display: flex;
                flex-direction: column;
                .title {
                    color: #ffffff;
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 12px;
                    text-align: left;
                    @media screen and (max-width: 480px) {
                        align-items: flex-start;
                    }
                    .border-2px {
                        width: 40px;
                        border: 2px solid #ffffff;
                    }
                }

                ul {
                    list-style-type: none;
                    padding: 0;
                    gap: 12px;
                    display: flex;
                    flex-direction: column;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 0;

                    @media screen and (max-width: 480px) {
                        flex-wrap: wrap;
                        flex-direction: row;
                    }

                    li {
                        a {
                            color: #ffffff;
                        }
                    }
                }
            }

            .footer-brands {
                margin-top: 32px;
                margin-bottom: 32px;
                @media screen and (min-width: 1024px) {
                    margin-top: 0;
                    // width: 26.5%;
                    width: 30%;
                    margin-bottom: 0;
                }
                @media screen and (min-width: 1200px) {
                    // width: 32%;
                    width: 47%;
                }
                .footer-brand-lists {
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }
                .footer-brand-lists-desktop {
                    display: none;
                    @media screen and (min-width: 768px) {
                        display: inline-block;
                    }
                }
            }
            .footer-quick-links {
                margin-bottom: 32px;
                @media screen and (min-width: 768px) {
                    // width: 25%;
                    width: 30%;
                }
                @media screen and (min-width: 1200px) {
                    // width: 31%;
                    width: 40%;
                    margin-bottom: 0;
                }
                .quick-links {
                    display: flex;
                    gap: 12px;
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                    .footer-lists {
                        align-items: flex-start;
                        flex-direction: column;
                        margin-bottom: 12px;
                    }
                }
                .quick-links-desktop {
                    display: none;
                    @media screen and (min-width: 768px) {
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer-lists {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 12px;
                        .footer-list-quick {
                            width: 100%;
                            span {
                                border-bottom: 2px solid transparent;
                                transition: all 0.2s ease-in-out;
                                cursor: pointer;
                            }
                            span:hover {
                                // border-bottom: 2px solid $white;
                                color: #cccccc;
                            }
                            & > a {
                                border-bottom: 2px solid $white;
                                border-bottom: 2px solid transparent;
                                transition: all 0.5s ease-in-out;
                                &:hover {
                                    border-bottom: 2px solid $white;
                                }
                            }
                        }
                    }
                }
            }
            .footer-address-line {
                @media screen and (min-width: 768px) {
                    width: 30%;
                }
                @media screen and (min-width: 1024px) {
                    width: 40%;
                }

                .footer-address {
                    margin-bottom: 16px;
                    transition: all 0.2s ease-in-out;
                    &:hover {
                        color: #cccccc;
                    }
                }
                .footer-email {
                    margin-bottom: 12px;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .footer-number {
                    margin-bottom: 18px;
                    cursor: pointer;
                }
                p:not(.footer-subtitle) {
                    font-style: normal;
                    font-size: 14px;
                    line-height: 140%;
                    color: #ffffff;
                    @media screen and (min-width: 768px) {
                        font-size: 16px;
                    }
                }
                .footer-socials {
                    @include flex-center;
                    gap: 20px;
                    cursor: pointer;
                    img {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }
        .footer-copyright {
            background-color: $main-dark-blue;
            @include regular-font;

            line-height: 16px;
            color: #ffffff;
            padding: 24px 15px 24px 15px;
        }
    }
    .footer-copyright {
        display: flex;
        flex-direction: column;
        gap: 24px;
        background-color: $main-dark-blue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: $white;
        text-align: center;
        padding: 24px 15px 24px 15px;
        justify-content: center;
        align-items: center;
        .footer-copyright-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 18.2px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 6px;
            @media screen and (min-width: 1024px) {
                max-width: 840px;
            }
        }
        @media screen and (min-width: 768px) {
            font-size: 16px;
        }
        p {
            font-weight: 400;
            // margin-top: 0.5rem;
        }
    }
    .flag-img {
        width: 25px;
        // height: 25px;
        height: 16px;
        margin-bottom: 4px;
    }
    .country-link {
        color: inherit;
    }
}

.textAlignLeft {
    text-align: left !important;
}

.textAlignRight {
    text-align: right !important;
}
.flag-seperator {
    border: 0.5px solid #ffffff;
    height: 14px;
    margin-top: 4px;
    margin-left: 8px;
    display: inline-block;
}

// @media only screen and (max-width: 1199px) {
//     .footer-top {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//     }
//     .footer-top > .footer-top-available {
//         min-width: fit-content;
//         flex: 1;
//     }
// }
