.car-loan-calculator-padding {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        padding: 40px;
    }
    .car-loan-calculator-top-right-circle {
        position: absolute;
        background-color: $bright-yellow;
        border-radius: 50%;
        width: 442px;
        height: 442px;
        display: none;

        @media screen and (min-width: 768px) {
            display: block;
            top: -55px;
            right: -126px;
        }
    }
}

.car-detail-car-loan {
    display: flex;
    gap: 24px;
    scroll-margin-top: 205px;

    .car-loan-cover-image {
        width: 51%;
        display: none;
        height: 639px;

        @media screen and (min-width: 768px) {
            display: block;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    .calculator {
        width: 100%;
        background-color: white;
        z-index: 1;
        border: 1px solid $medium-white;
        border-radius: 5px;
        padding: 23px 24px;

        display: flex;
        gap: 16px;
        flex-direction: column;
        flex-grow: 1;

        @media screen and (min-width: 768px) {
            width: 46%;
        }

        .calculator-heading {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .loan-calculator-title {
                font-weight: 700;
                font-size: 25px;
                line-height: 29px;
                color: $main-dark-blue;
            }

            .loan-calculator-subtitle {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: $grey-scale-dark-grey;
            }
        }

        .calculator-content {
            display: flex;
            flex-direction: column;
            gap: 24px;
            .calculator-form {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .calculator-form-element {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    // remove antd before element
                    .ant-radio-button-wrapper:not(:first-child)::before {
                        content: none !important;
                    }

                    .loan-calculator-form-item.tenure-year {
                        .ant-form-item {
                            margin-bottom: 0px;
                        }
                    }

                    .car-loan-form-slider {
                        margin-bottom: 4px;

                        .loan-calculator-slider {
                            .ant-slider-rail {
                                background-color: #d8d8d8;
                                height: 6px;
                                border-radius: 5px;
                            }

                            .ant-slider-track {
                                background-color: #012367;
                                height: 6px;
                                border-radius: 5px;
                            }
                            .ant-slider-handle {
                                height: 19px;
                                width: 19px;
                                border: none;
                                box-shadow: 0px 0.8px 2.39px 0px #0000004d;
                            }
                        }
                    }

                    .car-loan-tenure-radio-group {
                        display: flex;
                        gap: 8px;

                        .tenure-button {
                            border-radius: 5px;
                            border: 1px solid $grey;
                            padding: 7px;
                            flex-grow: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 16px !important;
                            line-height: 18px;
                            height: auto !important;
                        }
                        .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
                            background-color: $main-blue;
                        }
                    }

                    .slider-label {
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        gap: 2px;

                        @media screen and (min-width: 768px) {
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .slider-label-header {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 20px;
                        }

                        .slider-label-value {
                            border-radius: 5px;
                            border: 1px solid $grey;
                            padding: 7px;
                            color: #333333;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 19px;
                        }
                    }

                    .slider-value-range {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        line-height: 18px;
                        color: $light-black;
                    }
                    .tenure-year {
                        display: flex;
                        gap: 8px;
                        flex-direction: column;
                        .tenure-label {
                            color: $light-black;
                            font-weight: 700;
                            line-height: 20px;
                            font-size: 14px;
                        }
                    }
                }
            }

            .monthly-payment-result-section {
                border-radius: 5px;
                background-color: #f5f9ff;
                padding: 16px;
                display: flex;
                flex-direction: column;
                gap: 16px;

                .monthly-payment-result {
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 16px;

                    .monthly-payment-result-title {
                        margin-bottom: 8px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        color: $main-blue;
                    }

                    .monthly-payment-value {
                        font-size: 32px;
                        font-weight: 700;
                        text-align: center;
                        line-height: 38px;
                    }
                }

                .callback-btn {
                    padding: 14px 12px;
                    background-color: $main-blue;
                    color: #ffffff;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                }
            }
        }
    }
}
