.our-team {
    .top-title {
        padding-block: 60px;
        margin-inline: auto;
        .align {
            padding: 15px;
            @media screen and (min-width: 768px) {
                padding: 30px;
            }
            @media screen and (min-width: 1024px) {
                padding: 0;
                margin-inline: auto;
                width: 80%;
            }
        }
    }
    p {
        @include regular-font;
        color: $medium-black;
        margin-bottom: 30px;
    }
    .our-team__content {
        padding: 15px;
        @media screen and (min-width: 768px) {
            padding: 30px;
        }
        @media screen and (min-width: 1024px) {
            padding: 0;
            margin-inline: auto;
            width: 66.2%;
        }
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 140%;
            color: $main-dark-blue;
            margin-bottom: 8px;
            padding-top: 30px;
        }
    }
    .our-team__content {
        padding: 15px;
        @media screen and (min-width: 768px) {
            padding: 30px;
        }
        @media screen and (min-width: 1024px) {
            padding: 0;
            margin-inline: auto;
            width: 66.2%;
        }
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 140%;
            color: $main-dark-blue;
            margin-bottom: 8px;
            padding-top: 30px;
        }
    }
}
