@import '../sectionutils';

.car-detail-why-auto24 {
    padding-top: 48px;
    padding-bottom: 48px;

    .why-auto24-section-title{
        @include section-container-title();
        margin-bottom: 24px;
    }

    .why-auto24-items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;

        .why-auto24-list-item {
            padding: 24px 32px;
            background-color: $light-white;
            display: flex;
            align-items: center;
            gap: 20px;
            .why-aut24-list-item-text {
                font-size: 16px;
                color: $light-black;
                line-height: 22px;
            }
        }

        @media screen and (min-width: 640px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: 640px) {
            grid-template-columns: repeat(3, 1fr);
        }


    }
}