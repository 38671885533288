.car-card-box {
    width: 100%;
    height: 100%;

    &:hover {
        cursor: pointer;
    }
}

// card image section
.car-card-box-image {
    position: relative;
    object-fit: cover;
    aspect-ratio: 31/20;

    img {
        border-radius: 5px 5px 0px 0px;
        object-fit: cover;
        overflow: hidden;
    }
}

.car-card-box-status {
    position: absolute;

    color: #ffffff;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    padding: 4px 8px;
    border-radius: 3px;

    &.left {
        left: 12px;
        top: 12px;
    }

    &.right {
        top: 12px;
        right: 12px;
    }

    &.new {
        background-color: #20b038;
    }

    &.discount {
        background-color: #ff4637;
    }
}

.featured-cars-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

// card content
.car-card-box-content {
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    top: -2px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.featured-car-monthly-pill {
    padding: 6px;
    border-radius: 5px;
    background-color: #ffffff;

    &.bottom-right {
        position: absolute;
        bottom: 8px;
        right: 8px;
    }
}

.car-card-box-monthly-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $main-blue;
}

.featured-content-divider {
    border: 0;
    height: 2px;
    background-color: rgba(153, 212, 180, 0.1);
}

.car-card-box-title {
    @include title-style();
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #212121;

    @media screen and (max-width: 768px) {
        @include text-style-lg();
    }

    span {
        text-transform: capitalize;
    }
}

.car-card-box-features {
    color: #8b8b8b;
    font-size: 14px;
    line-height: 19px;
    margin: 12px 0px;
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 6px;
}

.car-card-box-features-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

    img {
        width: 13px;
        height: 13px;
        object-fit: contain;
    }
}

.car-card-box-price {
    margin-top: 12px;
    @include title-style();

    @media screen and (max-width: 768px) {
        @include text-style-lg();
    }

    .card-price {
        margin-right: 12px;
    }
}

.featured-car-discount {
    color: $medium-grey;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    // text-decoration: line-through solid 2px #B3B3B3;
    margin: auto 0;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% - 1px);
        height: 2px;
        background-color: $medium-grey;
    }
}
