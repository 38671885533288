.car-feature-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 17px;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .feature-item {
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        gap: 6px;
        color: $medium-black;
    }
}
