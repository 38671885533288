@import './sectionutils';

.blog-section-main-container {
    background-color: white;
    padding-top: 56px;
    padding-bottom: 56px;

    @media (max-width: 768px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

.blog-section-title {
    width: 100%;

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: $light-black;

        @media screen and (min-width: 640px) {
            font-size: 14px;
            line-height: 20px;
        }

        @media screen and (min-width: 1200px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .desktop-only {
        display: none;
    }
    .mobile-only {
        display: inline;
    }

    @media screen and (min-width: 640px) {
        .desktop-only {
            display: inline;
        }
        .mobile-only {
            display: none;
        }
    }
}

.blog-section-main-header {
    margin-bottom: 16px;

    @media screen and (min-width: 640px) {
        margin-bottom: 34px;
    }
}

.blog-section-header {
    margin-top: 8px;
    @include section-container-heading-row(end);
}

.blog-section-heading {
    @include section-container-title();
    text-wrap: wrap;
    margin-bottom: 8px;
    display: inline;
}

.blog-section-cars {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    overflow: scroll;
    padding: 0 16px;

    @media screen and (min-width: 768px) {
        padding: 0 40px;
    }

    @media screen and (min-width: 1200px) {
        padding: 0 40px;
    }

    @media screen and (min-width: 1440px) {
        padding: 0;
        max-width: 1280px;
        margin-inline: auto;
        width: 100%;
    }

    @media screen and (min-width: 1500px) {
        max-width: 1440px;
    }
}

.blog-section-detail-link {
    @include section-detail-link();
    display: flex;
    text-wrap: nowrap;
}
