.car-details-specification-list {
    padding-top: 16px !important;
    padding-bottom: 16px;
    display: flex;
    gap: 8px;
    color: $grey-scale-dark-grey;
    font-size: 14px;

    overflow: scroll;

    .specification-item {
        padding: 8px;
        border: 1px;
        border-color: transparent;
        background-color: transparent;
        cursor: pointer;
        border-style: solid;
        border-radius: 5px;
        text-wrap: nowrap;

        &.active {
            border-color: $main-dark-blue;
            color: $black;
            font-weight: 700;
            background-color: $extra-white;
        }
    }

}