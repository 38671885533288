.bread-crumbs {
    margin-bottom: 24px;
    &.arrow-bread-crumbs {
        margin-bottom: 12px;
    }

    &.arrow-bread-crumbs {
        margin-bottom: 12px;
    }

    ol {
        align-items: center;
        gap: 6px;
    }

    .ant-breadcrumb li:last-child {
        color: unset;
    }

    .breadcrumb-seperator {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $dark-gray;
    }

    .ant-breadcrumb-link {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: $light-black;
    }
}

.arrow-bread-crumb {
    li {
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-breadcrumb-separator {
            display: flex;
            justify-content: center;
        }

        .ant-breadcrumb-link a {
            color: $grey-scale-dark-grey;
            font-size: 14px;
        }

        .active {
            color: $medium-black;
        }
    }

    &.ant-breadcrumb li:last-child a {
        color: $medium-black !important;
    }
}
