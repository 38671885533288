.user-input-box {
    margin-bottom: 0px !important;
    border-radius: 5px !important;
    // overflow: hidden;
    .ant-form-item-explain-error {
        margin-top: 3px;
        margin-left: 3px;
        font-size: 14px;
        line-height: 1.4;
        @include regu-font;
        font-size: 14px;
    }
}
.error-message {
    @include regu-font;
    font-size: 14px;
    color: #ff4637;
}
.margin {
    margin-bottom: 24px !important;
}
.ant-form-item-explain {
    position: absolute;
}
.terms-condt-listing {
    display: block;
    @include regular-font;
    color: $dark-gray;
    margin-top: 20px;
    span {
        cursor: pointer;
        text-decoration: underline;
        color: $light-blue;
        @media (max-width: 720px) {
            display: inline;
        }
    }
}
.signup-form-wrapper {
    margin-top: 20px;
    input {
        cursor: text;
        &::placeholder {
            @include input-placeholder;
        }
    }
    .terms-condt {
        display: block;
        margin-top: 20px;
        @include regular-font;
        color: $dark-gray;
        .termandpolicy {
            text-decoration: underline;
            color: $main-blue;
            @media (max-width: 720px) {
                display: inline;
            }
        }
    }
    .sign-in-redirect {
        @include regular-font;
        color: $dark-gray;
        span {
            cursor: pointer;
            color: $main-blue;
            @include bold-font;
            font-size: 16px;
        }
    }
    .sign-up-button {
        margin-top: 24px;
        padding: 14px;
    }
}

.signin-form-wrapper {
    margin-top: 32px;
    input:not([type='checkbox']) {
        cursor: text;
        &::placeholder {
            @include input-placeholder;
        }
    }
    .sign-up-redirect {
        color: $dark-gray;
        @include regular-font;
        display: block;
        margin-top: 16px;
        span {
            cursor: pointer;
            color: $main-blue;
            @include bold-font;
            font-size: 16px;
        }
    }

    .sign-in-button {
        margin-top: 24px;
        padding: 14px;
    }

    .remember-forgot-section {
        @include flex-center;
        justify-content: space-between;
        margin-top: 24px;
        @media (max-width: 375px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .remember-me {
            @include flex-center;
            @include regular-font;
            color: $dark-gray;
            cursor: pointer;
            .check-box {
                margin-right: 12px;
                .ant-checkbox-inner {
                    height: 24px;
                    width: 24px;
                    border: 2px solid #b3b3b3;
                    border-radius: 5px;
                    &::after {
                        left: 30%;
                        transform: rotate(45deg) scale(1.2)
                            translate(-50%, -50%);
                    }
                }
            }
            label {
                cursor: pointer;
                // font-size: 16px;
            }
            span {
                @include regular-font;
                color: $dark-gray;
            }
        }
        .forgot-password {
            @include regular-font;
            color: $dark-gray;
            cursor: pointer;
            font-size: 14px;
            &:hover {
                color: $main-blue;
            }
            @media (max-width: 375px) {
                margin-top: 10px;
            }
        }
    }
}

.reset-form-wrapper {
    margin-top: 8px;
    input {
        cursor: text;
        &::placeholder {
            @include input-placeholder;
        }
    }
    .reset-text {
        @include regular-font;
        display: block;
        margin-bottom: 31px;
        color: $light-black;
    }
    .reset-ant-form {
        .ant-form-item {
            margin-bottom: 0;
        }
    }
    .sign-in-redirect {
        @include regular-font;
        color: $dark-gray;
        display: block;
        margin-top: 14px;
        span {
            cursor: pointer;
            color: $main-blue;
            @include bold-font;
            font-size: 16px;
        }
    }
    .sign-in-button {
        padding: 14px;
    }
}

.reset-successful {
    margin-top: 8px;
    span {
        display: block;
        margin-bottom: 36px;
        @include regular-font;
        color: $light-black;
    }
    img {
        width: 105px;
        height: 90px;
        @media (min-width: 375px) and (max-width: 600px) {
            width: 94px;
            height: 81px;
        }
    }
    .reset-success-button-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 36px;
        .reset-success-button {
            max-width: 171px;
            padding: 14px 0px;
            @media (min-width: 375px) and (max-width: 580px) {
                max-width: 136px;
                white-space: nowrap;
            }
            @media (max-width: 374px) {
                max-width: 110px;
                white-space: nowrap;
            }
        }
    }
    .reset-btn {
        margin-top: 12px;
    }
}
.or {
    text-align: center;
    color: $dark-gray;
    font-size: 16px;
    text-transform: capitalize;
    height: 23px;
    margin: 12px 0px;
}

.flex-justify-between {
    user-select: none;
    .icon {
        float: left;
        margin-right: 20px;
        font-size: 24px;
    }
    div {
        text-align: center;
    }
}
.overflow-visible {
    overflow: visible;
}
