.search-by-body-type-section {
    background-color: white;
}
.body-type-slider {
    .swiper-slide {
        background-color: transparent;
    }
}

.body-type-slider-card {
    cursor: pointer;
    img {
        aspect-ratio: 23/10;
        object-fit: contain;
    }

    .body-type-slider-text {
        margin-top: 8px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: $medium-black;
    }
}
