.car-overview-list {
    max-width: 580px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 80px;
    row-gap: 19px;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.overview-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;

    .overview-head {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 70%;

        .overview-icon {
            border-radius: 50%;
            background: linear-gradient(
                180deg,
                rgba(176, 222, 251, 0.3) 0%,
                rgba(194, 195, 252, 0.3) 100%
            );
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: 24px;
        }

        .overview-title {
            font-weight: 400;
            color: #6d6d6d;
        }
    }

    .overview-value {
        width: 30%;
        color: $black;
    }
}
