.review-card {
    background-color: $light-white;
    border-radius: 5px;
    padding: 12px;
    height: 100%;
    min-width: 300px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .review-header {
        margin-bottom: 16px;

        .review-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            .review-rating {
                display: flex;
                gap: 6px;
                align-items: center;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: $medium-black;
            }

            img {
                height: 24px;
                object-fit: contain;
                width: min-content;
            }
        }

        .review-content {
            font-size: 14px;
            line-height: 19px;
            font-weight: 400;
            color: $medium-black;
        }
    }

    .review-detail {
        .review-name {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: $medium-black;
        }

        .review-date {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $grey-scale-dark-grey;
        }
    }
}
