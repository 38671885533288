.loan-calculator {
    margin: 0 auto;
    padding-top: 60px !important;
    padding-bottom: 60px !important;
    @include flex-center;
    flex-direction: column;
    background: #fafafa;
    .heading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-weight: 500;
        padding: 0px 60px 0 60px;
    }
    .subtitle {
        width: 100%;
        line-height: 28px;
        text-align: center;
        margin-bottom: 40px;
        @media screen and (min-width: 768px) {
            width: 63%;
        }
    }
    .sub-title {
        @include regular-font;
        color: #595959;
    }
    .bankLogos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 28px;
        margin-bottom: 24px;
        & > img {
            height: 54px;
            width: unset;
            object-fit: cover;
            @media screen and (min-width: 768px) {
                height: 52px;
            }
            @media screen and (min-width: 1200) {
                height: 68px;
            }
            @media screen and (min-width: 1440px) {
                height: 78px;
                width: max-content;
            }
        }
        @media screen and (min-width: 1024px) {
            gap: 42px;
        }
        @media screen and (min-width: 1200px) {
            gap: 52px;
        }
        @media screen and (min-width: 1200px) {
            gap: 68px;
        }
    }
    .form-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        flex-direction: column;
        @media screen and (min-width: 1024px) {
            flex-direction: row;
        }
        .form-inputs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; /* Allow items to wrap to the next line */
            justify-content: space-between; /* Distribute space evenly between items */
            gap: 16px;
            background-color: #ffffff;
            //box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 24px;
            width: 100%;
            // @media screen and (min-width: 768px) {
            //     width: 50%;
            // }
            @media screen and (min-width: 1024px) {
                width: 50%;
            }
            @media screen and (min-width: 1440px) {
                width: 50%;
            }

            form.ant-form {
                width: 100%;
            }
            label {
                @include regular-font;
                color: #333333;
                //margin-bottom: 4px;
                @include flex-center;

                justify-content: space-between;
                margin-bottom: 10px;
                font-size: 16px;
            }
            .input-label {
                margin-bottom: 10px;
            }
            .form-input {
                border: 1px solid $medium-grey;
                border-radius: 5px;
                height: 48px;
                padding: 13px 10px;
                @include flex-center;
                gap: 13px;
                width: 100%;
                background-color: $extra-white;
                .input-icon {
                    @include flex-center;
                    @include regular-font;
                    gap: 10px;
                    color: $light-black;
                    border-right: 1px solid $light-black;
                    padding-right: 8px;
                }
                .form-icon {
                    width: 20px;
                    height: 20px;
                }
                input {
                    width: 100%;
                    @include input-font;
                    background-color: $extra-white;
                }
                input::placeholder {
                    @include input-placeholder;
                    @media screen and (min-width: 768px) {
                        font-size: 16px;
                    }
                }
            }
            .btn-container {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                .bottom-btn {
                    &:hover {
                        border-color: unset;
                        color: #ffffff;
                    }
                    &:active {
                        background: #175adc;
                        color: #ffffff;
                    }
                }
            }

            .form-input-container {
                gap: 8px;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(50% - 20px);
                display: flex;
                /* align-items: center; */
                flex-direction: column;
                @media screen and (max-width: 1024px) {
                    flex-basis: 100%;
                }
                .loan-range-cal {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    .min-value-container,
                    .max-value-container {
                        text-align: center;
                        line-height: 20px;
                    }
                    p {
                        text-align: center;
                        margin: 0 0 20px;
                        font-size: 16px;
                    }
                }

                .ant-select,
                .ant-input-number-group-wrapper,
                .ant-slider,
                .ant-form-item-explain-error {
                    display: block;
                }

                .ant-select-selector,
                .ant-input-number {
                    border-radius: 5px;
                }

                .ant-slider {
                    .ant-slider-rail {
                        height: 6px;
                    }
                    .ant-slider-track {
                        background-color: $main-dark-blue;
                        height: 6px;
                    }
                    .ant-slider-handle {
                        border: none;
                        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3) !important ;
                    }
                }
            }
            .slider-values {
                @include flex-center;
                justify-content: space-between;
                margin-top: 6px;
                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px !important;
                    line-height: 140%;
                    color: $light-black;
                }
            }
        }
        //.form-inputs:first-child {
        //    margin-bottom: 45px;
        //}
        .loan-total {
            @include flex-center;
            align-self: stretch;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 16px;
            background: #f5f9ff;
            //box-shadow: -5px 5px 30px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 24px;
            width: 30%;
            // @media screen and (max-width: 768px) {
            //     width: 100%;
            // }
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
            @media screen and (min-width: 1024px) {
                width: 50%;
            }
            .total-loan-container {
                background-color: #ffffff;
                padding: 14px;
                width: 100%;
                p {
                    font-style: normal;
                    font-weight: 700;
                    text-align: center;
                }
                .total-loan-title {
                    font-size: 18px;
                    line-height: 140%;
                    color: #07348d;
                }
                .total-loan {
                    //font-size: 38px;
                    font-size: 30px;
                    color: #000000;
                }
            }
            .bottom-btn {
                width: 100%;
            }
        }
    }
    & > .full-width {
        // width: 100%;
        // display: block;
        & > .form-inputs {
            width: 100%;
        }
        & > .loan-total {
            width: 50%;
            min-height: 100%;
            // display: grid;
            // grid-template-columns: repeat(1, 1fr);
            row-gap: 1rem;
            column-gap: 1rem;
            align-self: stretch;
            place-items: center;
            border-radius: 8px;
            padding: 16px;
            & > .monthly-payment {
                min-height: 110px;
                background: white;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding-top: 12px;
                padding-bottom: 12px;
                gap: 7px;
                width: 100%;
                border-radius: 4px;
                gap: 7px;
                .esmitamted-monthly-payment-section {
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    font-weight: 700;
                    gap: 4px;
                    .amount {
                        font-size: 25px;
                        line-height: 35px;
                        color: #175adc;
                        font-weight: 700;
                    }
                }
                .monthly-insurance-section {
                    font-size: 14px;
                    .title {
                        //styleName: Regular/13;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 18.2px;
                        text-align: center;
                        color: #454545;
                    }
                    .amount {
                        //styleName: Regular/16;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 22.4px;
                        text-align: center;
                        color: #175adc;
                    }
                }
            }
            .credit-amount {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 12px;
                // background-color: #ffffff;
                .loan-info-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;
                    // height: 25.2rem;
                    // max-width: 50%;
                    & > p {
                        white-space: normal;
                        word-wrap: break-word;
                    }

                    // background-color: #f5f9ff;
                    padding: 0 8px 0 8px;
                    p {
                        font-style: normal;
                        font-weight: 700;
                        text-align: center;
                    }
                    .loan-info-title {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 140%;
                        color: #454545;
                    }
                    .loan-info-value {
                        font-size: 16px;
                        font-weight: 700;
                        color: #091731;
                    }
                }
            }
            @media screen and (max-width: 940px) {
                /* Styles for mobile screens */
                width: 100%;
            }

            // @media screen and (min-width: 1024px) {
            //     grid-template-columns: repeat(3, 1fr);
            // }
            // @media screen and (min-width: 1440px) {
            //     grid-template-columns: repeat(4, 1fr);
            // }
        }
    }
}

.loanCalculator {
    &-label {
        & > span {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
        }
    }
    &-title {
        span,
        p {
            font-size: 16px !important;
        }
    }
}

.ant-tooltip-inner {
    font-family: 'Sofia-Pro' !important;
}
