.blog-detail {
    margin: 0 auto;
    margin-top: 33px;
    @media screen and (min-width: 1024px) {
        width: 83%;
        margin-top: 40px;
    }
    .blog-details-sep {
        margin-inline: 0;
    }
    .title {
        margin-bottom: 24px;
        text-align: start;
        @media screen and (min-width: 1024px) {
            margin-bottom: 8px;
        }
    }
    .blog-detail__seperator {
        margin: 0 0 8px;
        margin-top: 12px;
        margin-bottom: 8px;
    }
    .author-details {
        @include flex-center;
        gap: 8px;
        @include regular-font;
        margin-bottom: 16px;
        color: $light-black;
        li {
            width: 5px;
        }
        li::marker {
            width: 5px;
            margin-right: 0 !important;
        }
        .type {
            @include flex-center;
            gap: 8px;
        }
        .date {
            color: $dark-gray;
            text-transform: capitalize;
        }
    }
    .blog-detail__image {
        width: 100%;
        height: 35vh;
        border-radius: 5px;
        @media screen and (min-width: 1024px) {
            height: 405px;
        }
        margin-bottom: 26px;
        img {
            border-radius: 5px;
        }
        &.second {
            margin: 40px 0;
        }
    }
    .details-section {
        margin-bottom: 40px;

        .detail-title {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: $main-dark-blue;
            margin-bottom: 8px;
        }
        .description {
            h1 {
                padding-bottom: 8px;
            }
            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 120%;
                color: $main-dark-blue;
                padding-bottom: 8px;
            }
            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 120%;
                padding-bottom: 8px;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                color: $light-black;
            }
            p:not(:last-child) {
                padding-bottom: 10px;
            }
            strong {
                color: $main-dark-blue;
            }
            .ql-align-center {
                text-align: center;
            }
            .ql-align-right {
                text-align: right;
            }
            .ql-align-justify {
                text-align: justify;
            }
            .ql-indent-1 {
                margin-left: 40px;
            }
            .ql-indent-2 {
                margin-left: 60px;
            }
            .ql-indent-3 {
                margin-left: 80px;
            }
            img {
                width: revert-layer;
                max-width: 100%;
                cursor: default !important;
            }
            a {
                strong {
                    color: $main-blue;
                    text-decoration: underline;
                }
            }
            ul,
            ol {
                margin-bottom: 8px;
                margin-left: 20px;
                li {
                    font-size: 1rem;
                    margin-left: 20px;
                }
            }
        }
    }
    .details-section:last-child {
        padding-bottom: 32px;
        border-bottom: 1px solid #e0e0e0;
    }
}
