.price-card-container {
    margin-bottom: 16px;
    border: 1px solid $medium-white;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
        border-radius: 0;
        margin-bottom: 0;
    }

    .card-certifications,
    .view-count {
        @media screen and (max-width: 768px) {
            display: none !important;
        }
    }

    .price-info-card {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media screen and (max-width: 768px) {
            border-radius: 0;
        }
        .card-price {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            margin-right: 8px;
            color: $main-dark-blue;

            @media screen and (min-width: 768px) {
                font-size: 28px;
                line-height: 34px;
            }

            @media screen and (min-width: 1200px) {
                font-size: 32px;
                line-height: 38px;
            }
        }

        .price-card-car-discount {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: $medium-grey;
            position: relative;
            margin: auto 0;
            display: inline-block;

            @media screen and (min-width: 768px) {
                font-size: 22px;
            }

            @media screen and (min-width: 1200px) {
                font-size: 24px;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                top: calc(50% - 1px);
                height: 2px;
                background-color: $medium-grey;
            }
        }

        .monthly-price {
            font-size: 14px;
            line-height: 18px;
            color: $light-black;
            margin-bottom: 6px;
        }

        .change-currency-btn {
            color: $main-blue;
            font-size: 16px;
            line-height: 22px;
            text-decoration: underline solid;
            text-decoration-skip-ink: none;
            background-color: transparent;
        }

        .price-info-action-buttons {
            display: flex;
            gap: 12px;
            justify-content: space-around;

            .action-btn {
                border-radius: 5px;
                border: 1px solid;
                padding: 8px 16px;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
            }

            .buy-button {
                width: 100%;
                color: white;
                background-color: $main-blue;
                border: $main-blue;
            }

            .whats-app-link {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 2px;
                color: #009e60;
            }
        }
    }

    .view-count {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background-color: $medium-white;
        padding: 8px 0;
        font-size: 14px;
        line-height: 19px;
        color: $light-black;
    }
}
.card-certifications {
    display: flex;
    align-items: center;
    gap: 9px;

    .card-cert {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #009e60;
        font-size: 14px;
        line-height: 19px;

        img {
            height: 24px;
            width: 24px;
        }
    }

    .dot {
        height: 4px;
        width: 4px;
        background-color: $grey;
        border-radius: 50%;
    }
}

.sell-my-car-card {
    padding: 16px;
    background-color: $dark-blue-tint5;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        display: none;
    }

    .right-circle {
        background-color: $bright-yellow;
        border-radius: 50%;
        position: absolute;
        right: -45%;
        bottom: -120%;
        width: 300px;
        height: 300px;
    }

    .sell-card-content {
        position: relative;
        z-index: 10;

        .sell-card-header {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .sell-car-card-title {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: $black;
            }

            .sell-car-card-subtitle {
                font-style: 13px;
                line-height: 18px;
                color: $light-black;
                max-width: 345px;
            }
        }

        .sell-car-card-link {
            border-radius: 5px;
            border: 1px $grey solid;
            padding: 15px 48px;
            background-color: #ffffff;
            color: $main-dark-blue;
            font-weight: 700;
            font-size: 14px;
        }
    }
}

.car-highlights {
    display: flex;
    gap: 12px;

    .deal-pill {
        border-radius: 5px;
        padding: 6px 8px;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: center;

        &.hot-deal {
            background-color: #ff30301a;
            color: #ff3030;
        }

        &.low-price {
            background-color: #0892271a;
            color: #009e60;
        }

        &.clean {
            // TODO review color for like pill
            background-color: #0917311a;
            color: #091731;
        }
    }
}
