.car-detail-additional-photos {
    .additional-image-container {
        position: relative;
        cursor: pointer;
        width: 260px;

        &:hover {
            .overlay {
                display: flex;
            }
        }
    }
    .image-title {
        line-height: 18px;
        font-size: 14px;
        color: $medium-black;
    }

    .additional-image {
        height: 175px;
        width: 260px;
        object-fit: contain;
    }

    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(14, 14, 14, 0.5);
        pointer-events: none;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        display: none;
        .view-btn {
            background-color: $main-blue;
            pointer-events: none;
            border-radius: 5px;
            border: 1px $main-blue solid;
            padding: 8px 16px;
            color: #ffffff;
            font-weight: 700;
            font-size: 14px;
        }
    }
}
