.featured {
    @include flex-center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 64px;
    margin-bottom: 64px;

    img {
        transform: scale(1);
        transition: all 0.5s ease-out;
        &:hover {
            transform: scale(1.2);
        }
    }
    .title {
        margin-bottom: 8px;
    }
    .subtitle {
        text-align: center;
        margin-bottom: 32px;
    }
    .featured-container {
        @include flex-center;
        gap: 16px;
        width: 100%;
        cursor: pointer;
        flex-wrap: wrap;
        @media screen and (min-width: 768px) {
            flex-direction: column;
        }
        .contents {
            width: 100%;
            position: absolute;
            left: 0px;
            bottom: 0px;
            padding: 0 24px 24px 24px;
            background: linear-gradient(
                180deg,
                rgba(1, 1, 1, 0) 0%,
                rgba(2, 2, 2, 0.6) 45.31%,
                rgba(3, 3, 3, 0.77) 100%
            );
            .blog-type {
                width: fit-content;
                background: $main-blue;
                border-radius: 5px;
                @include regular-font;
                color: $white;
                text-align: center;
                padding: 6px 10px;
                margin-bottom: 8px;
                text-transform: uppercase;
            }
            .type {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: $white;
                text-transform: uppercase;
            }
            .content-title {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: $white;
                @media screen and (min-width: 1024px) {
                    font-size: 25px;
                }
            }
            .author {
                @include regular-font;
                color: $light-white;
                text-transform: capitalize;
            }
        }
        .left-image {
            position: relative;
            overflow: hidden;
            height: 350px;
        }
        .right-image {
            position: relative;
            overflow: hidden;
            height: 250px;
            @media screen and (min-width: 768px) {
                height: 350px;
            }
        }
        .featured-top {
            @include flex-center;
            gap: 16px;
            width: 100%;
            flex-wrap: wrap;
            .left-image {
                width: 100%;
                @media screen and (min-width: 768px) {
                    width: calc(100% * (2 / 3) - 6px);
                }
            }
            .right-image {
                width: calc((100% - 16px) / 2);
                @media screen and (min-width: 768px) {
                    width: calc(100% * (1 / 3) - 11px);
                }
            }
        }
        .featured-bottom {
            gap: 16px;
            @include flex-center;
            width: 100%;
            .right-image {
                width: calc((100% - 32px) * (1 / 3));
            }
        }
    }
}
