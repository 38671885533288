.tech-docs-page {
    .tech-docs-content {
        max-width: 998px;
        margin: 40px auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .tech-docs-banner {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            .subtitle {
                text-align: center;
            }
        }
        .tech-docs-table {
            padding: 32px;
            border: 1px solid #f2f2f2;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .docs-filter {
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 600px) {
                    flex-direction: column;
                    align-items: start;
                    flex-direction: column-reverse;
                }

                h3 {
                    font-family: Sofia-Pro;
                    font-weight: 500;
                    font-size: 16px;
                    color: #636363;
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }

                .search-input {
                    display: flex;
                    border: 1px solid #f2f2f2;
                    border-radius: 5px;
                    width: 50%;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px 12px;
                    height: 40px;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                    input {
                        width: 100%;
                        font-family: Sofia-Pro;
                        &::placeholder {
                            color: #8b8b8b;
                        }
                    }
                }
                .sort-search {
                    width: 40%;
                    @media (max-width: 600px) {
                        width: 60%;
                    }
                    @media (max-width: 500px) {
                        width: 100%;
                    }
                    .selects {
                        width: 100%;
                        height: 40px;
                        padding: 6px 12px;
                        border: 1px solid #f2f2f2;
                        background-color: transparent;
                        &::placeholder {
                            color: #8b8b8b;
                        }

                        .ant-select-selection-item {
                            text-transform: capitalize;
                        }
                    }
                }
            }
            .docs-table {
                width: 100%;
                -webkit-overflow-scrolling: touch;
                position: relative;
                max-height: 600px;
                overflow-y: auto;

                @media (max-width: 768px) {
                    overflow-x: auto;
                }
                table {
                    border: none;
                    border-collapse: separate;
                    border-spacing: 0 12px;
                    width: 100%;

                    @media (max-width: 768px) {
                        min-width: 500px;
                        overflow-x: auto;
                    }
                    th:not(:first-child) {
                        padding-right: 20px;
                    }

                    td:first-child,
                    th:first-child {
                        border-radius: 10px 0 0 10px;
                        min-width: 250px;
                    }
                    td:nth-child(2),
                    th:nth-child(2),
                    td:nth-child(3),
                    th:nth-child(3) {
                        min-width: max-content;
                    }

                    td:last-child,
                    th:last-child {
                        border-radius: 0 10px 10px 0;
                    }

                    th,
                    td {
                        text-align: left;
                        color: #212121;
                    }

                    th {
                        font-weight: 400;
                        font-size: 14px;
                        text-transform: capitalize;
                        @media (max-width: 768px) {
                            font-size: 13px;
                        }
                    }

                    th:not(:first-child) {
                        padding: 0 16px;
                    }
                    thead {
                        tr {
                            position: sticky;
                            top: 0;
                            padding: 10px;
                            background-color: #fff;
                            .docs-table-header {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                min-width: max-content;

                                svg {
                                    cursor: pointer;
                                }
                            }
                            td:first-child,
                            th:first-child,
                            td:last-child,
                            th:last-child {
                                border-radius: 0;
                            }
                        }
                    }
                    tbody {
                        .docs-name-file {
                            display: flex;
                            gap: 12px;
                            align-items: center;
                            img {
                                height: 40px;
                                width: 40px;
                                object-fit: contain;
                            }
                            span {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 1;
                                font-size: 16px;
                                font-weight: 700;
                                font-family: Sofia-Pro;
                                @media (max-width: 768px) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .docs-download {
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            padding: 12px;
                            border: 1px solid #e0e0e0;
                            border-radius: 5px;
                            justify-content: center;
                            width: max-content;
                            height: 40px;
                            cursor: pointer;

                            &:hover {
                                box-shadow: 0px 0px 2px #175adc7a;
                            }

                            span {
                                font-size: 14px;
                                font-family: Sofia-Pro;
                                font-weight: 700;
                                text-transform: uppercase;
                                margin-top: 3px;
                                @media (max-width: 768px) {
                                    font-size: 13px;
                                }
                            }
                        }

                        tr {
                            border: 1px solid #f2f2f2;
                            font-size: 14px;
                            border-radius: 5px;
                            overflow: hidden;
                            height: 72px;
                            td {
                                padding: 16px;
                                border: 1px solid #f2f2f2;

                                img {
                                    height: 15px;
                                    width: 21px;
                                    border-radius: 4px;
                                    margin-right: 6px;
                                    object-fit: cover;
                                }

                                @media (max-width: 768px) {
                                    font-size: 14px;
                                }
                            }
                        }
                        tr td:first-child {
                            border-right: none;
                            font-size: 16px;
                            color: #454545;
                            @media (max-width: 768px) {
                                font-size: 14px;
                            }
                        }

                        tr td:last-child {
                            border-left: none;
                        }

                        tr td:not(:first-child):not(:last-child) {
                            border: none;
                            border-top: 1px solid #f2f2f2;
                            border-bottom: 1px solid #f2f2f2;
                        }

                        .no-data-avaialble {
                            border: none;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .popular-blogs-tech-docs {
        .popular-blogs-header {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media screen and (min-width: 768px) {
                .subtitle {
                    text-align: center;
                }
            }
        }
    }
}
