.search-by-category-wrapper {
    background-color: $white;
    text-shadow: none !important;
    .search-by-category {
        width: 90%;
        margin: auto;
        padding-top: 64px;
        padding-bottom: 64px;
        @include flex-center;
        flex-direction: column;
        background-color: $white;
        @media screen and (min-width: 768px) {
            width: 80%;
        }
        .title {
            margin-bottom: 40px;
        }
        .subtitle {
            text-align: center;
            margin-bottom: 30px;
        }
        .search-category-bodytype {
            width: 100%;
            margin-bottom: 60px;
            @media screen and (min-width: 1441px) {
                max-width: 1200px;
                margin-inline: auto;
            }
            .body-type-items {
                @include flex-center;
                justify-content: center;
                flex-wrap: wrap;
                box-sizing: border-box;
                .body-type-item {
                    cursor: pointer;
                    width: 31%;
                    height: 114px;
                    padding: 5px;
                    margin-left: 12px;
                    border: 2px solid transparent;
                    @media screen and (min-width: 768px) {
                        width: 150px;
                        padding: 10px 14px 12px 18px;
                    }
                    @media screen and (min-width: 1024px) {
                        padding: 10px 14px 12px 18px;
                    }
                    .bodytype-image {
                        width: 100%;
                        height: 62px;
                        margin-bottom: 15px;
                        img {
                            object-fit: contain;
                        }
                    }
                    .bodytype-name {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 14px;
                        color: $medium-black;
                        text-align: center;
                    }
                    &:hover {
                        border: 2px solid #175adc;
                        border-radius: 5px;
                        height: 114px;
                    }
                }
            }
            .body-type-item.active {
                border: 2px solid #175adc;
                border-radius: 5px;
                height: 114px;
            }
        }
        .search-category-brandname {
            margin-bottom: 40px;
            @media screen and (min-width: 1441px) {
                max-width: 1200px;
                margin-inline: auto;
            }
        }
    }
    .brand-name-items {
        @include flex-center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
        @media screen and (min-width: 1024px) {
            justify-content: center;
            gap: 30px;
        }
        .brand-image {
            cursor: pointer;
            border: 2px solid transparent;
            padding: 5px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:hover {
                border: 2px solid #175adc;
                border-radius: 5px;
            }
            &.active {
                border: 2px solid #175adc;
                border-radius: 5px;
            }

            img {
                object-fit: contain;
            }
            .image-container {
                width: 50px;
                height: 50px;
                @media screen and (min-width: 768px) {
                    width: 80px;
                    height: 80px;
                }
            }
            .brand-name {
                @include brand-name;
            }
        }
    }
}

.show-cars-btn{
    height: 44px;
    min-width: 131px;
    padding: 12px 24px 12px 24px !important;
}
