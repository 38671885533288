.finance-top {
    @include flex-center;
    margin: 0 auto;
    flex: 1 1;
    gap: 35px;
    padding-top: 60px !important;
    margin-bottom: 60px;
    .title {
        margin-bottom: 12px;
    }
    .about-finance {
        width: 100%;
        @media screen and (min-width: 912px) {
            width: 50%;
        }
        .content {
            p {
                margin-bottom: 22px;
            }
        }
        p {
            @include regular-font;
            color: $medium-black;
            @media screen and (min-width: 912px) {
                text-align: left;
            }
        }
        ul, ol {
            margin: 16px;
        }
    }
    .about-finance-image {
        width: 47%;
        height: 459px;
        display: none;
        img {
            object-fit: contain;
        }
        @media screen and (min-width: 912px) {
            display: inline-block;
        }
    }
}
.about-promise {
    flex-direction: row-reverse;
    .content {
        h2 {
            font-weight: 700;
        }
        ul {
            margin-left: 14px;
        }
    }
}
