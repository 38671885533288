.footer-info-content {
    padding-block: 32px !important;
    // background-color: $white;

    .footer-subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $grey-scale-dark-grey;
        text-align: left;

        &.truncate-block {
            @include cramp-lines(4);
        }

        li {
            list-style-position: outside;
            margin-left: 0px;
            line-height: 22px;
        }

        a {
            text-decoration: underline;
        }

        p {
            margin-bottom: 16px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 16px;
            line-height: 1.15em;
        }

        @media screen and (min-width: 640px) {
            font-size: 15px;
            line-height: 20px;

            li {
                margin-left: 5px;
            }
        }

        @media screen and (min-width: 1200px) {
            font-size: 16px;
            line-height: 22px;

            li {
                margin-left: 10px;
            }
        }
    }

    .footer-read-more-btn {
        margin-top: 4px;
        background-color: transparent;
        color: $main-blue;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;

        @media screen and (min-width: 1024px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .footer-title {
        margin-bottom: 6px;
        text-align: left;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: $black;

        @media screen and (min-width: 640px) {
            font-size: 22px;
            line-height: 32px;
        }

        @media screen and (min-width: 1200px) {
            font-size: 25px;
            line-height: 35px;
        }
    }

    @media screen and (min-width: 640px) {
        padding-block: 40px !important;
    }

    @media screen and (min-width: 1024px) {
        padding-block: 50px !important;
    }

    @media screen and (min-width: 1400px) {
        padding-block: 56px !important;
    }
}
