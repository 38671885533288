@mixin section-container-heading-row ($align-item) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-bottom: 24px;
    gap: 4px;

    @media screen and (min-width: 640px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: $align-item;
    }
}

@mixin section-container-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $main-dark-blue;

    @media screen and (min-width: 640px) {
        font-size: 22px;
        line-height: 32px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 25px;
        line-height: 35px;
    }
}

@mixin section-detail-link {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: $main-blue;

    span {
        // margin-top: 4px;
    }


    @media screen and (min-width: 1200px) {
        font-size: 14px;
        line-height: 19px;
    }

    &:hover {
        color: $main-blue;
    }
}