.location-holder {
    width: 100%;
    background-color: $white;
    .location-container {
        margin: auto;
        @include flex-center;
        flex-direction: column;
        padding-block: 60px !important;
        position: relative;
        @media screen and (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
        }
        .location-header {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            @media screen and (min-width: 768px) {
                width: 100%;
            }
            @media screen and (min-width: 1024px) {
                width: 37%;
                text-align: left;
                margin: 0;
            }

            .title {
                margin-bottom: 8px;
            }
            .subtitle {
                margin-bottom: 24px;
            }
            .bottom-btn {
                display: none;
                margin-top: 24px;
                @media screen and (min-width: 1024px) {
                    display: inline-block;
                }
            }
        }
        .location {
            width: 100%;
            @media screen and (min-width: 1024px) {
                width: 60%;
            }
            .location-card-container {
                width: 100% !important;
            }
            .swiper-button-next {
                top: 50%;
            }
        }
        .bottom-btn {
            text-align: center;
            margin-top: 32px;
            @media screen and (min-width: 1024px) {
                display: none;
            }
        }
    }
}
.location-card {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        height: 250px;
    }
    &:hover {
        // .location-card-image {
        //     img {
        //         transform: scale(1.2);
        //     }
        // }
        .location-card-content {
            .content-title {
                text-decoration: underline;
            }
        }
    }
    .location-card-image {
        width: 100%;
        overflow: hidden;

        img {
            object-fit: cover;
            transform: scale(1);
            border-radius: 5px 0 0 5px;
            transition: all 0.5s ease-out;
        }
    }
    .location-card-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 5px;
        padding: 24px;
        .content-title {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            color: $main-dark-blue;
            margin-bottom: 16px;
            min-height: 40px;
        }
        .content-description {
            @include flex-center;
            gap: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: $light-black;
            margin-bottom: 20px;
            text-transform: capitalize;
            .map-pin {
                margin-left: -2px;
            }
        }
        .view-all-cars-link {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: $main-dark-blue;
            @include flex-center;

            gap: 10px;
            .view-all-cars {
                @include flex-center;

                gap: 10px;
            }
            .right-arrow {
                width: 8px;
                height: 13px;
            }
        }
    }
}
