.sell-my-car-section {
    background-color: $dark-blue-tint5;

    .banner-info-section-title {
        color: $black;
    }

    .banner-info-section-subtitle {
        color: $light-black;
    }

    .banner-info-link {
        background-color: $main-blue;
        color: white;
    }
}