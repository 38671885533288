.section-slider {

    button.cardArrow {
        border: 1px $main-dark-blue solid;
        background-color: #ffffff;
        border-radius: 5px;
        color: $light-black;
        &:disabled {
            display: none;
        }
    }

    &.remove-arrow-for-mobile {
        @media only screen and (max-width: 639px)  {
            button.cardArrow {
                display: none;
            }
        }
    }


    .swiper-slide {
        height: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }

}

.section-slider .swiper-slide{
    border-radius: 5px;
}