@import '../sectionutils';

.car-specification-detail-section {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        padding: 12px 0;
    }

    .car-specification-details {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .car-specification-about {
        .about-text {
            color: $light-black;
            font-size: 14px;
            line-height: 18px;

            &.truncate-text {
                @include cramp-lines(3);
            }
        }

        .read-more-btn {
            background-color: transparent;
            color: $main-blue;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
        }
    }

    .car-specification-price {
        flex-grow: 1;
        width: 100%;

        @media screen and (min-width: 768px) {
            max-width: 415px;
            position: sticky;
            min-width: 390px;
            top: 129px;
        }
    }
}

.feature-category-specification {
    display: flex;
    flex-direction: column;
    gap: 8px;
    scroll-margin-top: 205px;
}

.specification-card {
    background-color: #ffffff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid $medium-white;
    border-radius: 5px;
    scroll-margin-top: 205px;

    hr {
        border: 1px solid $medium-white;
    }

    .car-specification-card-title {
        font-weight: 700;
        line-height: 19px;
        font-size: 16px;
        color: $black;
    }
}

.sticky-price-block {
    position: sticky;

    .car-highlights {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    @media screen and (min-width: 768px) {
        top: 133px;
    }

    @media screen and (max-width: 768px) {
        position: fixed;
        bottom: 0;
        z-index: 99;
        width: 100%;
        margin-left: -16px;
    }

    @media screen and (min-width: 1024px) {
        top: calc(80px + 73px); // header + sticky car specification link tab
    }
}
